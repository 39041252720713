import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { isCheesyDesktopSafariVersion } from 'functions/isCheesyDesktopSafari'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'
import { replace } from 'functions/router'
import { fetchAbTestsGroupListAction } from 'actions/abTestAction'
import { ACTIVE_AB_TESTS } from 'api/abTestApi'
import { usePrevious } from 'hooks/usePrevious'
import { defineStartScreenPath } from 'functions/defineStartScreenPath'
import { fetchBannersAvailableAction } from 'actions/banner/bannerAction'
import { fetchIsAuthorizationMailruAction } from 'actions/authorizationAction'
import { isLoveMailru } from 'common/constants'
import { usePartner } from 'hooks/usePartner'
import { unregisterFirebaseMessagingServiceWorker } from 'functions/pwa/unregisterServiceWorkers'
import { fetchSettingServicesEnableAction } from 'actions/system/fetchSettingServicesEnableAction'
import { isEnabledSoundNotificationAction } from 'actions/settings/isEnabledSoundNotificationAction'
import { fetchFeaturesAction } from 'actions/system/fetchFeaturesAction'
import { fetchCurrentLocationAction } from 'actions/location/fetchCurrentLocationAction'

export const useDispatchInitialActions = () => {
  const dispatch = useDispatch()
  const { partnerId } = usePartner()

  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  useEffect(() => {
    dispatch(fetchCurrentLocationAction())

    if (authorized) {
      dispatch(isEnabledSoundNotificationAction())
      dispatch(fetchSettingServicesEnableAction())
    }
    if (!authorized && isLoveMailru(partnerId)) {
      dispatch(fetchIsAuthorizationMailruAction())
    }
  }, [authorized, dispatch, partnerId])
}

export const useDispatchAfterAuthorizationActions = () => {
  const dispatch = useDispatch()

  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  const prevAuthorized = usePrevious(authorized)

  useEffect(() => {
    if (!prevAuthorized && authorized) {
      dispatch(fetchAbTestsGroupListAction(ACTIVE_AB_TESTS))
      dispatch(fetchFeaturesAction())
    }
  }, [authorized, dispatch, prevAuthorized])
}

/**
 * Принудительно применяет полифил для десктопного сафари.
 * Например на версии 15.4 scroll({ behavior: 'smooth' }) вообще не скроллит https://redmine.mamba.ru/issues/118289
 */
export const useSmoothScrollPolyfillForCheesyDesktopSafari = () => {
  const { isSafari, browserVersion, touch } = useShallowEqualSelector(
    ({ systemReducer: { isSafari, browserVersion, touch } }) => ({
      isSafari,
      browserVersion,
      touch,
    })
  )

  const isDesktopSafari = useRef(Boolean(isSafari))

  useEffect(() => {
    if (!isDesktopSafari.current || !browserVersion) {
      return
    }

    if (!isCheesyDesktopSafariVersion(browserVersion)) {
      return
    }

    ;(async () => {
      const smoothscroll = await import('smoothscroll-polyfill')
      // @ts-expect-error
      window.__forceSmoothScrollPolyfill__ = true
      smoothscroll.polyfill()
    })()
  }, [browserVersion])
}
/** Чтобы не оставлять разбаненного юзера на экране: "Вы заблокированы" */
export const useWatchProfileUnbanned = () => {
  const { profileBanned, startScreen } = useShallowEqualSelector(
    ({ errorReducer: { profileBanned }, systemReducer: { startScreen } }) => ({
      profileBanned,
      startScreen,
    })
  )
  const previousProfileBanned = usePrevious(profileBanned)
  const dispatch = useDispatch()

  if (previousProfileBanned && !profileBanned) {
    dispatch(replace(defineStartScreenPath(startScreen)))
    dispatch(fetchMiniIfNeededAction())
  }
}

/**
 * Нужно, чтобы показать/скрыть рекламу, после смены VIP-статуса
 */
export const useAfterVipActivated = () => {
  const dispatch = useDispatch()
  const { vip } = useShallowEqualSelector(({ userReducer: { vip } }) => ({
    vip,
  }))

  const prevVip = usePrevious(vip)

  useEffect(() => {
    if (prevVip !== vip) {
      dispatch(fetchBannersAvailableAction())
    }
  }, [dispatch, prevVip, vip])
}

export const useUnregisterFirebaseMessagingServiceWorkerIfNeeded = () => {
  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  useEffect(() => {
    if (!authorized) {
      unregisterFirebaseMessagingServiceWorker()
    }
  }, [authorized])
}

export const useFetchMiniOnProfileRestored = () => {
  const dispatch = useDispatch()

  const { profileRemoved } = useShallowEqualSelector(
    ({ errorReducer: { profileRemoved } }) => ({
      profileRemoved,
    })
  )

  const prevProfileRemoved = usePrevious(profileRemoved)

  useEffect(() => {
    if (prevProfileRemoved && !profileRemoved) {
      dispatch(fetchMiniIfNeededAction())
    }
  }, [dispatch, prevProfileRemoved, profileRemoved])
}
