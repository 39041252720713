import { PartialRootState } from 'common/store/store.types'

export const RESTORE_PARTIAL_STATE = 'RESTORE_PARTIAL_STATE' as const

export interface RestorePartialStateAction {
  type: typeof RESTORE_PARTIAL_STATE
  partialState: PartialRootState
  name: keyof PartialRootState
}

export const restorePartialStateAction = (
  partialState: PartialRootState,
  name
) => ({
  type: RESTORE_PARTIAL_STATE,
  partialState,
  name,
})

export type LocalStorageActionTypes = RestorePartialStateAction
