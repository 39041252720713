import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { logoutApi } from 'api/authorizationApi'
import { resetErrorAction } from 'actions/errorAction'
import { apiResultWithoutErrors } from 'api/function/apiResultWithoutErrors'
import {
  fetchPartnerDataAction,
  switchLandingTypeAction,
  updateStartScreenAction,
} from 'actions/system/systemAction'
import { isMamba } from 'common/constants'
import { threeFeaturesLandingType } from 'components/landing/landing.constants'
import { resetVendorsListAction } from 'actions/authorization/vendorsListAction'
import { unregisterPushConsumerAction } from 'actions/push/unregisterPushConsumerAction'
import { resetUniNoticesAction } from 'actions/uninotice/resetUniNoticesAction'

export const LOGOUT_ACTION = 'LOGOUT_ACTION' as const

export interface LogoutAction extends AsyncAction<{ isAuth: boolean }> {
  type: typeof LOGOUT_ACTION
}

export const logoutPlainAction = () => ({
  type: LOGOUT_ACTION,
  promise: () => logoutApi(),
})

export const logoutAction = (): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  dispatch(resetErrorAction())
  dispatch(resetUniNoticesAction())

  // Отписываемся от доставки пушей по токену
  // Запрос должен быть выполнен до /logout
  await dispatch(unregisterPushConsumerAction())

  const { result: json } = ((await dispatch(
    logoutPlainAction()
  )) as unknown) as { result: unknown }

  if (apiResultWithoutErrors(json)) {
    const partnerId = getState().systemReducer.partnerId

    dispatch(updateStartScreenAction(null))
    dispatch(fetchPartnerDataAction())

    if (isMamba(partnerId)) {
      dispatch(switchLandingTypeAction(threeFeaturesLandingType))
    }

    dispatch(resetVendorsListAction())
  }
}

export const clientLogoutAction = (): AsyncThunkAction => async (dispatch) => {
  await dispatch(unregisterPushConsumerAction())

  return dispatch({
    type: LOGOUT_ACTION,
    promise: () =>
      Promise.resolve({
        result: {
          result: {},
        },
        ok: true,
      }),
  })
}
