import { SocialAuthorizationSuccessLoadable } from 'components/page/SocialAuthorization/SocialAuthorizationSuccessLoadable'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { CaptchaPageLoadable } from 'components/page/Captcha/CaptchaPageLoadable'
import {
  boundSocialAccountPath,
  diamondsPath,
  enterEmailPath,
  logoutPath,
  registrationPath,
  settingsModalPath,
} from 'components/paths'
import { BindingSocialAccountLoadable } from 'components/page/BindingSocialAccount/BindingSocialAccountLoadable'
import { DiamondsModalLoadable } from 'components/page/DiamondsInfo/DiamondsModalLoadable'
import {
  cityListPath,
  loginPath,
  loginVariantsPath,
  otherSocialNetworksPath,
  shortcutListPath,
} from 'components/page/Boarding/boarding.paths'
import { LoginLoadable } from 'components/page/Boarding/LoginLoadable'
import { RegistrationLoadable } from 'components/page/Boarding/RegistrationLoadable'
import {
  finalStepPath,
  stepRegistrationStepPath,
} from 'components/page/StepRegistration/StepRegistration.paths'
import { FoundComponent } from 'components/system/FoundComponent'
import { thenModalPath } from 'components/block/Then/Then.paths'
import { SocialAuthorizationFailLoadable } from 'components/page/SocialAuthorization/SocialAuthorizationFailLoadable'
import { LoginVariantsWithEmail } from 'components/page/Boarding/LoginVariantsWithEmail'
import {
  socialAuthorizationFailFullPath,
  socialAuthorizationSuccessPath,
} from 'components/page/SocialAuthorization/socialAuthorization.paths'
import { promoCodeRoutes } from 'components/page/PromoCode/promoCode.routes'
import { AppRoute } from 'components/components.types'
import { UnauthorizedRedirect } from 'components/system/UnauthorizedRedirect'
import { CaptchaReasonListLoadable } from 'components/page/Captcha/CaptchaReasonListLoadable'
import { CityListLoadable } from 'components/page/Boarding/CityListLoadable'
import { ShortcutListLoadable } from 'components/page/Boarding/ShortcutListLoadable'
import { LoginVariantsLoadable } from 'components/page/Boarding/LoginVariantsLoadable'
import {
  unauthorizedCaptchaPath,
  unauthorizedCaptchaReasonsPath,
} from 'components/page/Captcha/Captcha.paths'

const unauthorizedModalRoutes: AppRoute[] = [
  {
    path: mergeAllUrls(loginPath, thenModalPath),
    component: LoginLoadable,
    shouldCheck: true,
  },
  {
    path: loginPath,
    component: LoginLoadable,
  },
  {
    path: mergeAllUrls(loginPath, otherSocialNetworksPath, ':limit'),
    component: LoginVariantsLoadable,
  },
  {
    path: loginVariantsPath,
    component: LoginVariantsWithEmail,
  },
  {
    path: mergeAllUrls(registrationPath, thenModalPath),
    component: RegistrationLoadable,
  },
  { path: registrationPath, component: RegistrationLoadable },
  { path: enterEmailPath, component: () => null },
  ...promoCodeRoutes,
  {
    path: mergeAllUrls(otherSocialNetworksPath, ':limit'),
    component: LoginVariantsLoadable,
  },
  {
    path: mergeAllUrls(finalStepPath, otherSocialNetworksPath, ':limit'),
    component: LoginVariantsLoadable,
  },
  {
    path: socialAuthorizationFailFullPath,
    component: SocialAuthorizationFailLoadable,
  },
  {
    path: socialAuthorizationSuccessPath,
    component: SocialAuthorizationSuccessLoadable,
  },
  { path: unauthorizedCaptchaPath, component: CaptchaPageLoadable },
  {
    path: unauthorizedCaptchaReasonsPath,
    component: CaptchaReasonListLoadable,
  },
  { path: cityListPath, component: CityListLoadable },
  {
    path: shortcutListPath,
    component: ShortcutListLoadable,
  },
  {
    path: boundSocialAccountPath,
    component: BindingSocialAccountLoadable,
  },
  {
    path: diamondsPath,
    component: DiamondsModalLoadable,
  },
  {
    path: stepRegistrationStepPath,
    component: FoundComponent,
  },
  {
    path: logoutPath,
    component: UnauthorizedRedirect,
  },
  {
    path: settingsModalPath,
    component: UnauthorizedRedirect,
    skip: true,
  },
]

export default unauthorizedModalRoutes
