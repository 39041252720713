import React from 'react'
import loadable from '@loadable/component'

import { DefaultLoader } from '../../presentational/AnimatedWatch/AnimatedWatch'

export const emailRestoreDefaultPath = '/'
export const restorePasswordCrossPath = 'cross'
export const phoneAcceptedPath = 'enter-phone-code'
export const phoneResetSuccessPath = 'phone-success'
export const emailResetSuccessPath = 'email-success'
export const restorePasswordPath = '/restore-password'

export default loadable(() => import('./RestorePasswordIndex'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('reset-password split error', error)
    }
    return <DefaultLoader />
  })({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
})
