import { sPostCookie } from 'common/constants'
import { removeAllSPosts } from 'common-constants/cookie'
import { AsyncThunkAction } from 'actions/actions.types'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'

/**
 * https://redmine.mamba.ru/issues/116506
 */
export const checkCookiesAction = (): AsyncThunkAction<unknown> => (
  dispatch
) => {
  const cookies = document.cookie
    .split(';')
    .map((value) => value.trim().split('='))
    .filter(([name]) => name === sPostCookie)

  if (cookies.length > 1) {
    removeAllSPosts()
    dispatch(fetchMiniIfNeededAction())
  }
}
