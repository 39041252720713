import React, { FC } from 'react'
import { useLocation } from 'react-router'

import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { removeLocaleFromPath } from 'functions/language'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { DropSupportOldBrowserLoadable } from 'components/page/DropSupportOldBrowser/DropSupportOldBrowserLoadable'
import { isIeBrowser } from 'functions/isIeBrowser'

import { LandingCommonLoadable } from 'components/landing/LandingCommonLoadable'
import { authorizedRoutes } from 'components/layout/authorizedRoutes'
import {
  pathsWithMambaLayout,
  unauthorizedRoutes,
} from 'components/layout/allRoutes'
import { withLoader } from 'components/system/loadable/withLoader'
import loadable from '@loadable/component'

const MambaLayoutLoadable = loadable(
  () => import('components/layout/MambaLayout/MambaLayoutSwitch'),
  withLoader
)

export const IndexSwitcher: FC = () => {
  const { pathname } = useLocation()
  const {
    authorized,
    browserName,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      systemReducer: { browserName },
    }) => ({ authorized, browserName })
  )

  if (isIeBrowser(browserName!)) {
    return <DropSupportOldBrowserLoadable />
  }

  if (authorized) {
    return <MambaLayoutLoadable routes={authorizedRoutes} />
  }

  const url = mergeAllUrls(removeLocaleFromPath(pathname))
  const hasRoute = pathsWithMambaLayout.some((path) => url.indexOf(path) === 0)

  if (hasRoute) {
    return <MambaLayoutLoadable routes={unauthorizedRoutes} />
  }

  return <LandingCommonLoadable />
}
