import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useInterval } from 'hooks/useInterval'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { systemViewReducer } from 'reducers/system/systemViewReducer'

export const RepeatableAction: FC<{
  timeout: number
  action: Function
  visibleOnly?: boolean
}> = ({ timeout = 10000, action, visibleOnly = false }) => {
  useReducersInsert({ systemView: systemViewReducer })

  const dispatch = useDispatch()

  const { pageVisible } = useShallowEqualSelector(
    ({ systemView: { pageVisible } }) => ({
      pageVisible,
    })
  )
  const handleInterval = () => {
    if (!visibleOnly || pageVisible) {
      dispatch(action())
    }
  }
  useInterval(handleInterval, timeout)
  return null
}
