import React from 'react'
import { init as sentryInit, reactRouterV5Instrumentation } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { loadableReady } from '@loadable/component'
import { createRoot, hydrateRoot } from 'react-dom/client'

import ApplicationStart from './ApplicationStart'
import { push, replace } from 'functions/router'
import { mergeAllUrls } from 'functions/mergeAllUrls'
/**
 * Не удалять импорт history, Сентри не может подключится к переходам
 * страниц.
 */
import { historyFirstRedux, store, history } from './configureClientStore'

import { layoutSettingsPath } from 'components/layout/MambaLayout/paths'
import {
  findLocaleWithDefaultFallback,
  fixForReactIntl,
} from 'functions/locale'
import { getAppElement } from 'functions/getAppElement'
import {
  settingsVerificationPath,
  verifyBySocialLoadingPath,
} from 'components/page/Settings/Settings.paths'

import { updateShell } from 'functions/pwa/updateShell'
import { setUpYandexAds } from 'components/banner/yandex/setUpYandexAds'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { isInSearchEnginesCache } from './isInSearchEnginesCache'
import { checkSession } from './checkSession'

import { secretParam, uidParam } from 'common/constantsAuthBySecret'
import { createSearch } from 'functions/createSearch'

import { captchaLoadedAction } from 'actions/captchaAction'
import { installServiceWorkerAction } from 'actions/pwa/installServiceWorkerAction'
import { updateQueryAction } from 'actions/system/updateQueryAction'
import { authorizeBySecretAction } from 'actions/authorization/authorizeBySecretAction'
import { setTag } from '@sentry/core'
import { polyfill } from './polyfills'
import { shellPath } from 'components/page/Pwa/Pwa.paths'
import { updateReferrerAction } from 'actions/system/systemAction'

if (process.env.SENTRY_ENABLED) {
  const sentryUrl =
    process.env.SENTRY_CLIENT_URL ||
    'https://866cc3d7bf144f6ca6be8a4a4e5565ec@api.mobile-api.ru/6'
  /**
   * Отладочный урл, для SaaS Сентри
   */
  // const sentryUrl =
  //   'https://7ff01d9a79036ac9358b78b6eda697d4@o4505538710863872.ingest.sentry.io/4506025404268544'
  console.info('Sentry url', sentryUrl)
  const env = process.env.SENTRY_ENV
  const release = process.env.RELEASE
  const sampleRate = process.env.production ? 0.1 : 1

  console.info('Sentry env', env)
  console.info('Sentry release', release)
  console.info('Sentry sample rate', sampleRate)
  /**
   * Дефолтовый АПИ Сентри:
   * sentry.mamba.ru/api/6/envelope/
   * Наша дырка:
   * api.mobile-api.ru/api/6/envelope/
   */
  sentryInit({
    dsn: sentryUrl,
    integrations: [
      new BrowserTracing({
        // shouldCreateSpanForRequest: (url) => {
        //   // console.log('shouldCreateSpanForRequest', url)
        //   return true
        // },
        /**
         * Пример самостоятельной реализацией:
         * https://github.com/getsentry/sentry-javascript/issues/4779
         * https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/
         */
        routingInstrumentation: reactRouterV5Instrumentation(history),
        tracePropagationTargets: [
          'localhost',
          /^\/api/,
          /^\/mobile\/api/,
          /^\/node\/api/,
          // /^https:\/\/yourserver\.io\/api/,
        ],
        /** вроде по дефолту отслеживается, но не вижу в логах, проставляю для уверенности */
        startTransactionOnLocationChange: true,
        traceFetch: true,
        // beforeNavigate: (context /* TransactionContext */) => {
        //   console.log('beforeNavigate', context)
        //   return context
        // },
      }),
      // new HttpClient(),
    ],
    release: release,
    // debug: true,
    // По-идее должна включить полный стек трейс, согласно cli его нет
    attachStacktrace: true,
    tracesSampleRate: sampleRate,
    normalizeDepth: 3,
    normalizeMaxBreadth: 5,
    maxBreadcrumbs: 10,
    // beforeSendTransaction: (event, hint) => {
    //   console.info('transaction', event, { hint })
    //   return event
    // },

    /** Фильтрация: https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/ */
    ignoreErrors: [
      /**
       * https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
       * > You can safely ignore this error.
       *
       * https://stackoverflow.com/questions/65732703/resizeobserver-loop-limit-exceeded-stop-exception
       */
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      /** Ошибка библиотеки hls.js, стримы закрываем, поэтому игнорируем */
      'this.log is not a function',
      /** Для iOS При попытке воспроизвести звук входящего сообщения. Через try catch не подавляется. */
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
      // Тоже что и выше, только для Chrome.
      // prettier-ignore
      'NotAllowedError: play() failed because the user didn\'t interact with the document first.',
      /**
       * Ошибка порождена из AppNetworkError, проблемы с сетью могут быть разные,
       * смысла сохранять в Сентри нет.
       *
       * Если будет какое-то расследование, можно попробовать включить.
       *
       * Скрываются так же и CORS-ошибки, но по-идее их отловим в регрессе.
       */
      'Failed to fetch',
      'TypeError: Failed to fetch',
      'Load failed',
      'отменено',
      'NetworkError when attempting to fetch resource.',
    ],

    denyUrls: [
      /**
       * https://youtrack.mamba.ru/issue/M-3907
       * /ads/js/tfav_adl_55.js
       * https://sentry.mamba.ru/organizations/mamba/issues/3091342/?project=6
       */
      /ads\/js/i,
      'https://yandex.ru/ads/system/context.js',
    ],
    environment: env,
  })
}

const { dispatch } = store
const { locale } = store.getState().systemReducer
export const internalLocale = findLocaleWithDefaultFallback(locale)

window.onloadCallback = () => dispatch(captchaLoadedAction())

const pwaSupported = isPwaSupported()
setTag('pwa', pwaSupported ? 'yes' : 'no')
setTag('country', store.getState().userReducer.countryId)

if (pwaSupported) {
  dispatch(updateQueryAction())
}

// mamba2/.templates/ru/default/verify_real_using_social_network/verify.tpl:19
window.onSocialVerifyCallback = ({ state, provider, code }) => {
  // dispatch(verifySocialWindowAction(params))
  dispatch(
    push(
      `${mergeAllUrls(
        layoutSettingsPath,
        settingsVerificationPath,
        verifyBySocialLoadingPath
      )}?state=${state}&provider=${provider}&code=${code}`
    )
  )
}

window.uniWeb = true
window.pushCallback = (path) => dispatch(push(path))
window.replaceCallback = (path) => dispatch(replace(path))
window.tryUpdateShell = updateShell

setUpYandexAds()

const waitLoadable = () =>
  new Promise<void>((resolve: () => void) => {
    loadableReady(resolve).catch(console.error)
  })

if (isInSearchEnginesCache()) {
  console.warn('Search engine cache')
} else {
  const startUniweb = async () => {
    const promises = [
      waitLoadable(),
      checkSession(),
      polyfill(fixForReactIntl(internalLocale)),
    ]

    const search = createSearch()

    const uid = search.get(uidParam)
    const secret = search.get(secretParam)

    /**
     * Авторизуем на старте клиента, чтобы не допустить параллельных запросов,
     * которые получать ответ, что юзер не авторизован.
     **/
    if (uid && secret) {
      promises.push(dispatch(authorizeBySecretAction(uid, secret)) as any)
    }

    await Promise.all(promises)

    if (
      window.__INITIAL_STATE__.router.location.pathname.startsWith(shellPath)
    ) {
      /**
       * Так как запроса к серверу не было, надо обновить реферрер на клиенте.
       */
      store.dispatch(updateReferrerAction(document.referrer))

      const root = createRoot(getAppElement())
      root.render(
        <ApplicationStart
          locale={fixForReactIntl(internalLocale)}
          history={historyFirstRedux}
        />
      )
    } else {
      hydrateRoot(
        getAppElement(),
        <ApplicationStart
          locale={fixForReactIntl(internalLocale)}
          history={historyFirstRedux}
        />,
        {
          onRecoverableError: (error, errorInfo) => {
            /** Так как не фатальное, оставляют так */
            console.warn('Hydrate recoverable error', error, errorInfo)
            // captureException(new Error('Hydrate root error'))
          },
        }
      )
    }
  }

  startUniweb().catch(console.error)
}

dispatch(installServiceWorkerAction())
