import React, { FC } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { Replace } from 'components/system/Replace'
import { indexPath, profilePath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const LegacyProfileRedirect: FC = () => {
  const { authorized, id } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized, profile } }) => ({
      authorized,
      id: profile?.id,
    })
  )

  if (authorized) {
    return (
      <Replace
        to={mergeAllUrls(profilePath, id)}
        uid="LegacyProfileRedirect-authorized"
      />
    )
  }

  return <Replace to={indexPath} uid="LegacyProfileRedirect" />
}
