import { vendorsListApi } from 'api/authorization/vendorsListApi'
import { AsyncThunkAction } from 'actions/actions.types'
import { NodeHeaders } from 'api/NodeHeaders'

export const fetchVendorsListIfNeededAction = (
  headers?: NodeHeaders
): AsyncThunkAction => (dispatch, getState) => {
  const { authorizationReducer, systemReducer } = getState()
  const { vendorsRequested, vendorsLoaded } = authorizationReducer
  const { locale } = systemReducer

  if (vendorsRequested || vendorsLoaded) {
    return
  }

  return dispatch(fetchVendorsAction(locale, headers))
}

export const FETCH_VENDORS_LIST = 'FETCH_VENDORS_LIST' as const

export const fetchVendorsAction = (locale: string, headers?: NodeHeaders) => ({
  type: FETCH_VENDORS_LIST,
  promise: () => vendorsListApi(headers),
})

export const RESET_VENDORS_LIST = 'RESET_VENDORS_LIST' as const

export const resetVendorsListAction = () => ({
  type: RESET_VENDORS_LIST,
})

export type VendorsAction =
  | ReturnType<typeof fetchVendorsAction>
  | ReturnType<typeof resetVendorsListAction>
