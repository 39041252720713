import { BannerData } from 'components/banner/banner.types'

/**
 * Нужно для работы с баннеркой https://adv.mamba.ru/
 */
export const setUpYandexAds = () => {
  window.yaContextCb = window.yaContextCb || []

  /**
   * Сразу в loadable, чтобы успеть принять от баннерки.
   */
  window.yaBannerQueue = []

  /**
   * Через функцию, чтобы потом можно было подменить механизм,
   * если понадобится.
   * @param banner
   */
  window.pushBanner = (banner: BannerData) => {
    pushBanner(banner)
  }
}

export const pushBanner = (banner: BannerData) =>
  window.yaBannerQueue.push(banner)
