import {
  SAVE_COMPONENT_SCROLL_Y,
  SAVE_SCROLL_Y,
  TOGGLE_RESTORE_COMPONENT_SCROLL_Y,
  UPDATE_PAGE_VISIBILITY,
  ViewActionTypes,
} from 'actions/system/viewAction'

export interface SystemViewState {
  savedScrollYValue: number
  componentVerticalScrollValue: Record<string, number>
  componentNeedRestoreScroll: Record<string, boolean>
  pageVisible: boolean
}

export const systemViewReducer = (
  state: SystemViewState = {
    savedScrollYValue: 0,
    componentVerticalScrollValue: {},
    componentNeedRestoreScroll: {},
    pageVisible: true,
  },
  action: ViewActionTypes
): SystemViewState => {
  switch (action.type) {
    case SAVE_SCROLL_Y:
      return {
        ...state,
        savedScrollYValue: action.value,
      }

    case SAVE_COMPONENT_SCROLL_Y:
      return {
        ...state,
        componentVerticalScrollValue: {
          ...state.componentVerticalScrollValue,
          [action.id]: action.value,
        },
      }

    case TOGGLE_RESTORE_COMPONENT_SCROLL_Y:
      return {
        ...state,
        componentNeedRestoreScroll: {
          ...state.componentNeedRestoreScroll,
          [action.id]: action.value,
        },
      }

    case UPDATE_PAGE_VISIBILITY:
      return { ...state, pageVisible: action.value }
    default:
      return state
  }
}
