import loadable from '@loadable/component'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const InviteFriendLoadable = loadable(
  () => import('./InviteFriendPromoIndex'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('invite friend promo split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
