import { fetchApi } from 'api/index'

interface ClientStatPostBodyRequest {
  timers: ClientTimer[]
}

export const webSocketService = 'websocket'
export const webComponentService = 'WebComponent'

export const searchOperation = 'search'
export const ratingOperation = 'rating'
export const feedOperation = 'feed'
export const profileOperation = 'profile'
export const landingOperation = 'landing'
export const geoLandingOperation = 'geo_landing'

export type Service = typeof webSocketService | typeof webComponentService
export type Operation =
  | 'connect'
  | 'fail_connect'
  | 'subscribe'
  | 'get_message'
  | typeof searchOperation
  | typeof ratingOperation
  | typeof profileOperation
  | typeof landingOperation
  | typeof geoLandingOperation
  | typeof feedOperation

export interface ClientTimer {
  service: Service
  operation: Operation
  context: string
  time: number
  endTime: number
}

export const saveClientStatisticApi = (value: ClientStatPostBodyRequest) =>
  fetchApi('/client_stat', {
    method: 'POST',
    body: JSON.stringify(value),
  })
