import React, { FC, useCallback } from 'react'
import { WithChildren } from 'common/types'
import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react'
import { replace } from 'functions/router'
import { internalServerErrorPath } from 'components/paths'
import { useDispatch } from 'react-redux'

export const ErrorBoundaryWithRedirect: FC<{ uid: string } & WithChildren> = ({
  uid,
  children,
}) => {
  const dispatch = useDispatch()

  const handlerError = useCallback<NonNullable<ErrorBoundaryProps['onError']>>(
    (error) => {
      console.error('Error boundary', uid, error)
      dispatch(replace(internalServerErrorPath))
    },
    [dispatch, uid]
  )

  return (
    <ErrorBoundary
      onError={handlerError}
      beforeCapture={() => {
        console.error('Error boundary: before capture', uid)
      }}
    >
      {children}
    </ErrorBoundary>
  )
}
