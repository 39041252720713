import { apiResultWithoutErrors } from 'api/function/apiResultWithoutErrors'
import { postLoginAction } from 'actions/authorization/postLoginAction'
import { clearStepRegistrationAction } from 'actions/form/stepRegistrationAction'
import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { StartScreen } from 'common/constantsStartScreen'
import { loginApi } from 'api/authorization/tokenLoginApi'
import { updateLastAuthMethodAction } from 'actions/authorizationAction'

export const LOGIN_ACTION = 'LOGIN_ACTION' as const

export interface LoginAction extends AsyncAction<LoginActionResponse> {
  type: typeof LOGIN_ACTION
}

interface LoginActionResponse {
  isAuth: boolean
  startScreen: StartScreen | null
}

/**
 * @param isBindingWithSocialNetworkAccount - флаг, отправленный в запросе /login/builder, который говорит о том что это привязка аккаунта.
 */
export const loginAction = (
  isBindingWithSocialNetworkAccount?: boolean
): AsyncThunkAction => async (dispatch, getState) => {
  const { authorizationReducer, systemReducer } = getState()
  const { form, authorizing } = authorizationReducer
  const { password, computerLevel } = form

  if (authorizing) {
    return
  }

  const { result: json } = await dispatch<LoginAction>({
    type: LOGIN_ACTION,
    promise: () =>
      loginApi(
        form.login,
        password,
        computerLevel,
        systemReducer.partnerId,
        systemReducer.locale,
        isBindingWithSocialNetworkAccount
      ),
  })

  if (apiResultWithoutErrors(json) && json?.isAuth) {
    dispatch(clearStepRegistrationAction())
    dispatch(postLoginAction())
    if (systemReducer.redesign) {
      dispatch(updateLastAuthMethodAction('email'))
    }
  }
}
