import { StepRegistrationState } from 'reducers/registration/stepRegistrationReducer'
import {
  birthdayPath,
  selectTargetGenderPath,
  enterNamePath,
  selectAgePath,
  yourGenderPath,
  datingGoalPath,
  constitutionPath,
  personHeightPath,
  personWeightPath,
  finalStepPath,
} from 'components/page/StepRegistration/StepRegistration.paths'
import { pathsOrder } from 'components/page/StepRegistration/StepRegistration.constants'
import { indexPath } from 'components/paths'

export const findFirstNotFilledStepUrl = (
  stepRegistration: StepRegistrationState
) => {
  const {
    form: { name, day, month, year },
    gender,
    targetGender,
    age,
    datingGoal,
    constitutions,
    personHeight,
    personWeight,
  } = stepRegistration
  if (name === '') {
    return enterNamePath
  }
  if (!gender) {
    return yourGenderPath
  }
  if ([day, month, year].some((value) => value === '')) {
    return birthdayPath
  }
  if (!targetGender) {
    return selectTargetGenderPath
  }
  if (!age) {
    return selectAgePath
  }
  if (!datingGoal) {
    return datingGoalPath
  }
  if (constitutions.length === 0) {
    return constitutionPath
  }
  if (!personHeight) {
    return personHeightPath
  }
  if (!personWeight) {
    return personWeightPath
  }
  return finalStepPath
}

export const findNextStepUrl = (currentPath: string | null) => {
  if (currentPath === null) {
    return pathsOrder[0]
  }

  const stepIndex = pathsOrder.indexOf(currentPath)

  if (stepIndex === pathsOrder.length - 1) {
    return finalStepPath
  }

  return pathsOrder[stepIndex + 1]
}

export const findPrevStepUrl = (currentPath: string) => {
  const stepIndex = pathsOrder.indexOf(currentPath)

  // Идем назад с final-step
  if (stepIndex === -1) {
    const stepIndex = pathsOrder.slice(-1)[0]
    return pathsOrder[stepIndex]
  }

  // Чтобы не было лишнего редиректа с enter-gender
  if (stepIndex === 1) {
    return indexPath
  }

  return pathsOrder[stepIndex - 1]
}
