export const cometActions = {
  replace: 'replace',
  remove: 'remove',
  publish: 'publish',
}

export const createHitListChannel = (userId: number): string =>
  `mamba.hit.total.${userId}`

export const createHitListUpdatedSignalChannel = (userId: number): string =>
  `mamba.hit_list.${userId}`

/**
 * Канал входящих сообщении
 * @param userId
 */
export const createLastUserMessageChannel = (userId: number): string =>
  `mamba.messenger.last_user.${userId}`

/**
 * Канал исходящих сообщении
 * @param userId
 */
export const createOutgoingMessageLastUserChannel = (userId: number): string =>
  `mamba.messenger.last_user_out.${userId}`

export const createMessengerCounterChannel = (userId: number): string =>
  `mamba.messenger.counter.${userId}`

export const createVideoChatChannel = (userId: number): string =>
  `mamba.videochat.${userId}`

export const createUniNoticeChannel = (userId: number): string =>
  `mamba.uni_notice.${userId}`

export const createMessageReadedChannel = (userId: number): string =>
  `mamba.messenger.message_readed.${userId}`

export const createVipStatusChannel = (userId: number) =>
  `mamba.vip.status_changed.${userId}`

export const createRemovedMessageChannel = (userId: number) =>
  `mamba.messenger.message_removed.${userId}`

export const createTypingMessageUserChannel = (userId: number): string =>
  `mamba.messenger.message_typing_progress.${userId}`

export const createMessageEditedChannel = (userId: number): string =>
  `mamba.messenger.message_edited.${userId}`

export const createSupportTickerClosedChannel = (userId: number): string =>
  `mamba.support.ticket_closed.${userId}`

export const createMessengerIgnoredChannel = (userId: number): string =>
  `mamba.messenger.ignored.${userId}`

export const createMessengerAutodeleteChannel = (userId: number): string =>
  `mamba.messenger.autodelete.${userId}`

export const createMessengerRequestForCommunicationCountChannel = (
  userId: number
) => `mamba.messenger.request_for_communication_count.${userId}`

export const createMessengerRequestForCommunicationStatusChangedChannel = (
  userId: number
) => `mamba.messenger.request_for_communication_status_changed.${userId}`

/**
 * https://developers.wamba.com/comet/mamba_sales_order_delivered__userId_-publish/index.html
 * @param userId
 */
export const createOrderDeliveredChannel = (userId: number): string =>
  `mamba.sales.order_delivered.${userId}`

export const createGiftUserChannel = (userId: number): string =>
  `mamba.gifts.user.${userId}`

/**
 * https://redmine.mamba.ru/issues/113282#note-17
 * @param userId
 */
export const createAccountBalanceChannel = (userId: number): string =>
  `mamba.billing.account_balance_changed.${userId}`

export const createInviteFriends = (
  userId: number,
  parsingId: string
): string => `mamba.inviter.contacts.${userId}.${parsingId}`

export const PHONE_VERIFICATION_MOBILE_ID_SUCCESS_COMET_CHANNEL =
  'mamba.phone_verification.mobile_id_success'

export const createPhoneVerificationMobileIdSuccessChannel = (
  userId: number
): string => `${PHONE_VERIFICATION_MOBILE_ID_SUCCESS_COMET_CHANNEL}.${userId}`

export const PROFILE_UNBAN_CHANNEL = 'mamba.anketa.unban'

export const createUserUnbanChannel = (userId: number): string =>
  `${PROFILE_UNBAN_CHANNEL}.${userId}`

/**
 * @param userId
 * неиспользуемые каналы.
 */
export const createNewContactChannel = (userId: number): string =>
  `mamba.messenger.new_contact.${userId}`

export const createMessengerMessageChannel = (userId: number): string =>
  `mamba.messenger.message.${userId}`

// Реакции в мессенджере
export const createMessageAddReactionChannel = (userId: number): string =>
  `mamba.messenger.reactions.add_reaction.${userId}`

export const createMessageReplaceReactionChannel = (userId: number): string =>
  `mamba.messenger.reactions.replace_reaction.${userId}`

export const createMessageRemoveReactionChannel = (userId: number): string =>
  `mamba.messenger.reactions.remove_reaction.${userId}`

export const createMessageReadReactionChannel = (userId: number): string =>
  `mamba.messenger.reactions.read_reaction.${userId}`

/** Были открыты/закрыты все фото контакта */
export const createAttachmentVisibilityContactModifiedChannel = (
  userId: number
): string => `mamba.messenger.attachment_visibility.contact_modified.${userId}`

/** Были открыты/закрыты все фото сообщения */
export const createAttachmentVisibilityMessageModifiedChannel = (
  userId: number
): string => `mamba.messenger.attachment_visibility.message_modified.${userId}`

export const createRemovedIncomingMessageChannel = (userId: number): string =>
  `mamba.messenger.message_removed_for_recipient.${userId}`
