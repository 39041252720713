export const FIELD_PATH_ABOUT_ME = 'about-me'
export const FIELD_PATH_LOOK_FOR = 'look-for'
export const FIELD_PATH_LOOK_FOR_AGE_RANGE = 'look-for-age-range'
export const FIELD_PATH_HEIGHT = 'height'
export const FIELD_PATH_WEIGHT = 'weight'
export const FIELD_PATH_BIRTH_DATE = 'birth-date'
export const FIELD_PATH_EDUCATION = 'education'
export const FIELD_PATH_CONSTITUTION = 'constitution'
export const FIELD_PATH_DATING_GOALS = 'dating-goals'
export const FIELD_PATH_MARITAL_STATUS = 'marital-status'
export const FIELD_PATH_SMOKING_ATTITUDE = 'smoking-attitude'
export const FIELD_PATH_ALCOHOL_ATTITUDE = 'alcohol-attitude'
export const FIELD_PATH_MATERIAL_STATUS = 'material-status'
export const FIELD_PATH_HOME_STATUS = 'home-status'
export const FIELD_PATH_CHILDREN = 'children'
export const FIELD_PATH_APPEARANCE = 'appearance'
export const FIELD_PATH_ORIENTATION = 'orientation'
export const FIELD_PATH_KNOWN_LANGUAGES = 'known-languages'
export const FIELD_PATH_NAME = 'name'
export const FIELD_HOROSCOPE_SIGN = 'horoscope-sign'

export const FIELD_PATH_BIRTH_DATE_CONFIRM = 'confirm'

export const FIELD_NAME_ABOUT_ME = 'aboutMe'
export const FIELD_NAME_LOOK_FOR = 'lookFor'
export const FIELD_NAME_LOOK_FOR_AGE_RANGE = 'lookForAgeRange'
export const FIELD_NAME_HEIGHT = 'height'
export const FIELD_NAME_WEIGHT = 'weight'
export const FIELD_NAME_BIRTH_DATE = 'birthDate'
export const FIELD_NAME_EDUCATION = 'education'
export const FIELD_NAME_CONSTITUTION = 'constitution'
export const FIELD_NAME_DATING_GOALS = 'datingGoals'
export const FIELD_NAME_MARITAL_STATUS = 'maritalStatus'
export const FIELD_NAME_SMOKING_ATTITUDE = 'smokingAttitude'
export const FIELD_NAME_ALCOHOL_ATTITUDE = 'alcoholAttitude'
export const FIELD_NAME_MATERIAL_STATUS = 'materialStatus'
export const FIELD_NAME_HOME_STATUS = 'homeStatus'
export const FIELD_NAME_CHILDREN = 'children'
export const FIELD_NAME_APPEARANCE = 'appearance'
export const FIELD_NAME_ORIENTATION = 'orientation'
export const FIELD_NAME_KNOWN_LANGUAGES = 'knownLanguages'
export const FIELD_NAME_NAME = 'name'

export const TRANSLATE_PATH_WEIGHT = 'weight'

export const TRANSLATE_PATH_NAME = 'name'

export const fieldPathExcludedList = {
  [FIELD_NAME_MARITAL_STATUS]: FIELD_PATH_MARITAL_STATUS,
}

export const fieldPathList = {
  [FIELD_NAME_NAME]: FIELD_PATH_NAME,
  [FIELD_NAME_BIRTH_DATE]: FIELD_PATH_BIRTH_DATE,
  [FIELD_NAME_ABOUT_ME]: FIELD_PATH_ABOUT_ME,
  [FIELD_NAME_LOOK_FOR]: FIELD_PATH_LOOK_FOR,
  [FIELD_NAME_LOOK_FOR_AGE_RANGE]: FIELD_PATH_LOOK_FOR_AGE_RANGE,
  [FIELD_NAME_HEIGHT]: FIELD_PATH_HEIGHT,
  [FIELD_NAME_WEIGHT]: FIELD_PATH_WEIGHT,
  [FIELD_NAME_EDUCATION]: FIELD_PATH_EDUCATION,
  [FIELD_NAME_CONSTITUTION]: FIELD_PATH_CONSTITUTION,
  [FIELD_NAME_DATING_GOALS]: FIELD_PATH_DATING_GOALS,
  [FIELD_NAME_SMOKING_ATTITUDE]: FIELD_PATH_SMOKING_ATTITUDE,
  [FIELD_NAME_ALCOHOL_ATTITUDE]: FIELD_PATH_ALCOHOL_ATTITUDE,
  [FIELD_NAME_MATERIAL_STATUS]: FIELD_PATH_MATERIAL_STATUS,
  [FIELD_NAME_HOME_STATUS]: FIELD_PATH_HOME_STATUS,
  [FIELD_NAME_CHILDREN]: FIELD_PATH_CHILDREN,
  [FIELD_NAME_APPEARANCE]: FIELD_PATH_APPEARANCE,
  [FIELD_NAME_ORIENTATION]: FIELD_PATH_ORIENTATION,
  [FIELD_NAME_KNOWN_LANGUAGES]: FIELD_PATH_KNOWN_LANGUAGES,
  [FIELD_PATH_BIRTH_DATE_CONFIRM]: FIELD_PATH_BIRTH_DATE_CONFIRM,
}

export const fieldWithSingleSelect = [
  FIELD_NAME_CONSTITUTION,
  FIELD_NAME_MARITAL_STATUS,
  FIELD_NAME_EDUCATION,
  FIELD_NAME_SMOKING_ATTITUDE,
  FIELD_NAME_ALCOHOL_ATTITUDE,
  FIELD_NAME_MATERIAL_STATUS,
  FIELD_NAME_CHILDREN,
  FIELD_NAME_APPEARANCE,
  FIELD_NAME_HOME_STATUS,
  FIELD_NAME_ORIENTATION,
]

export const fieldWithoutSkipButton = [
  FIELD_NAME_NAME,
  FIELD_NAME_BIRTH_DATE,
  FIELD_NAME_ABOUT_ME,
  FIELD_NAME_LOOK_FOR,
  FIELD_NAME_LOOK_FOR_AGE_RANGE,
  FIELD_PATH_BIRTH_DATE_CONFIRM,
]

export enum ErrorFieldName {
  nameHasForbiddenCharacters = 'name_has_forbidden_characters',
  nameHasMixedLocaleCharacters = 'name_has_mixed_locale_characters',
  nameHasTooManyWords = 'name_has_too_many_words',
  nameHasTooShortWords = 'name_has_too_short_words',
  nameIsForbidden = 'name_is_forbidden',
  nameIsTooLong = 'name_is_too_long',
  nameIsTooShort = 'name_is_too_short',
}
