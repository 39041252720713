import {
  InlineNoticeTypes,
  UniNoticeId,
} from 'components/uninotice/uninotice.types'

/**
 * @deprecated Новые добавлять в uninotice.types.ts
 */
export const advancedSearchRestrictedBackendId = 'advanced_search_restricted'
export const emptySearchResultNoticeId = 'empty-search-result'
export const incognitoRequiredNoticeId = 'incognito-access-request-required'
export const incognitoRequiredSentNoticeId =
  'incognito-access-request-already-sent'
export const incognitoRequestDeclinedNoticeId =
  'incognito-access-request-rejected'
export const incognitoCurrentUserEnabledNoticeId =
  'incognito-is-currently-enabled'
export const newMessageNoticeId = 'messaging-new-message'
export const ratingVotingLimitExceededId = 'rating-voting-limit-exceeded'
export const ratingVotingVipLimitExceeded = 'rating-voting-vip-limit-exceeded'

export const photoBlockedStatusNoticeId = 'photo-rejected'
export const photoModerationStatusNoticeId = 'photo-on-moderation'
export const photoPendingStatusNoticeId = 'photo-on-pending'

export const photoUploadStartedNoticeId = 'photo-upload-started'
export const photoUploadFinishedNoticeId = 'photo-upload-finished'
export const photoUploadFailedNoticeId = 'photo-upload-failed'
export const photoUploadLimitNoticeId = 'photo-upload-limit-exceeded'
export const popUpBlockedInBrowser = 'photo-social-browser-window-blocked'
export const userNotAccessProvided = 'photo-social-access-not-provided'

export const notInSearchMakeTopNoticeId = 'maketop-user-not-in-search'
export const alreadyInProgressMakeTopNoticeId = 'maketop-already-in-progress'

export const featuredPhotosNotAllowedInIncognito =
  'featured-photos-not-allowed-in-incognito'
export const sharingUrlCopiedId = 'sharing-url-copied'
export const photoDeleteConfirmation = 'photo-delete-confirmation'
export const greetingRejectedNoticeId = 'greeting-rejected'
export const messagingAddFavoriteWhenIncognitoNoticeId =
  'messaging-add-favorite-when-incognito'
export const messagingStartWithGiftWhenNotIncognitoNoticeId =
  'messaging-start-with-gift-when-not-incognito'
export const supportTicketsRatingRequiredNoticeId =
  'support-tickets-rating-required'
export const photoVerificationInfoNoticeId = 'photo-verification-info'

export const maketopUsernameOnModeration = 'maketop-username-on-moderation'
export const notSupportedWebCameraNoticeId = 'action-not-supported-by-browser'

export const teamoTestNoticeId = 'teamo-test'
export const externalMessengerOpenForAuthId = 'external-messenger-open-for-auth'

export const stopChatDatingUnreadLimitNoticeId =
  'stop-chat-unread-messages-limit-hit'
export const stopChatPhotoVerificationRequiredNoticeId =
  'stop-chat-photo-verification-required'
export const stopChatUsernameOnModerationNoticeId =
  'stop-chat-username-on-moderation'

export const stopChatSupportTicketSuggestedNoticeId =
  'support-tickets-suggested'

export const profileNotFoundNoticeId = 'profile-not-found'

export const inviteFriendsNoticeId = 'inviter-promo'

/**
 * Те notice который должны сделать replace в url
 * В теории они все должны быть replace, кроме none
 */
export const replaceUrlNoticeId = [
  // https://redmine.mamba.ru/issues/108135
  // restrictedSearchNoticeId,
  incognitoRequiredNoticeId,
  incognitoRequiredSentNoticeId,
  incognitoRequestDeclinedNoticeId,
  UniNoticeId.photoUploadRules,
  // https://redmine.mamba.ru/issues/106190
  // photoUploadFailedNoticeId,
  // photoUploadLimitNoticeId,
  featuredPhotosNotAllowedInIncognito,
  notInSearchMakeTopNoticeId,
  alreadyInProgressMakeTopNoticeId,
  // https://redmine.mamba.ru/issues/111403
  maketopUsernameOnModeration,
  // https://redmine.mamba.ru/issues/112787
  // нужен реплейс, т.к. гифт можно открыть, а стикеры сами покажут стопчат
  UniNoticeId.stop_chat_user_by_ignoring_notice_id,
  InlineNoticeTypes.tele2,
  UniNoticeId.stopChatByRequestForCommunicationPhotoRequired,
]
