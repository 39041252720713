import { mergeAllUrls } from 'functions/mergeAllUrls'
import { go, push, replace } from 'functions/router'
import { defineStartScreenPath } from 'functions/defineStartScreenPath'
import { StartScreen } from 'common/constantsStartScreen'
import { isHistoryEmpty } from '../../../client/functions/isHistoryEmpty'
import { fromPayPalOrBillingExchange } from 'hooks/useGoBackAfterPayPal'
import { resetReferrerAction } from 'actions/system/resetReferrerAction'
import { findStepsBackCount } from 'reducers/locationsHistory/locationsHistoryReducer.functions'
import { indexPath } from 'components/paths'
import { goBack } from 'redux-first-history'
import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'

export const navigateModalAction = (method: Function, ...paths: string[]) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => dispatch(method(mergeAllUrls(getState().systemReducer.baseUrl, ...paths)))

export const pushBaseUrlAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => dispatch(push(getState().systemReducer.baseUrl))

export const pushModalAction = (...paths: string[]) =>
  navigateModalAction(push, ...paths)

export const replaceModalAction = (...paths: string[]) =>
  navigateModalAction(replace, ...paths)

export const pushModalBaseUrlAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => dispatch(push(mergeAllUrls(getState().systemReducer.baseUrl)))

export const goBackAction = (forceGoBack?: boolean) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  /**
   * Открытие некоторых модалов происходит по прямой ссылке, например:
   * app/agreement/vip
   */
  if (forceGoBack) {
    dispatch(goBack())
    return
  }

  const {
    locationsHistory,
    systemReducer: { baseUrl },
    router: {
      location: { pathname },
    },
  } = getState()

  const hasPreviousLocation = locationsHistory.browserHistory.length > 1

  if (!hasPreviousLocation && baseUrl === pathname) {
    dispatch(replace(indexPath))
    return
  }

  if (
    hasPreviousLocation ||
    baseUrl === pathname // Не модал
  ) {
    dispatch(goBack())
    return
  }

  dispatch(replace(baseUrl))
}

/**
 * Находит в истории локаций path и возвращает на N шагов назад в window.location.history.
 *
 * К примеру ты хочешь вернуться со страницы А на страницу Б.
 * В сценарии, когда ты сам до нее дошел и Б есть в истории, сделается go(-N)
 * Если страницы в истории нет, обычно это из-за того, что сделали рефреш страницы, отправляем юзера на нужную страницу с помощью replace
 *
 * @param path путь, куда нужно вернуться
 * @param forceIndex логика, если не нашли путь, вернуться в индекс
 */
export const goBackToAction = (path: string, forceIndex?: boolean) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const { locationsHistory } = getState()

  const stepsBackCount = findStepsBackCount(
    locationsHistory.browserHistory,
    path
  )

  if (stepsBackCount) {
    dispatch(go(stepsBackCount))
    return
  }

  dispatch(replace(forceIndex ? indexPath : path))
}

/**
 * Возвращает нас к baseUrl
 */
export const goBackToBaseUrlAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  dispatch(goBackToAction(getState().systemReducer.baseUrl))
}

/**
 * Возврат из витрин, учитывающий PayPal.
 */
export const goBackStorefrontAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  if (fromPayPalOrBillingExchange(getState().systemReducer.referrer)) {
    dispatch(resetReferrerAction())
    dispatch(go(-2))
  } else {
    dispatch(goBackAction())
  }
}

export const replaceBaseUrlAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  return dispatch(replace(getState().systemReducer.baseUrl))
}

export const goStartScreenPath = () => (dispatch: AppDispatchNext) =>
  dispatch(push(defineStartScreenPath(StartScreen.voting)))

export const goBackOrRatingPathAction = () => (dispatch: AppDispatchNext) => {
  if (isHistoryEmpty()) {
    dispatch(goStartScreenPath())
  } else {
    dispatch(goBackAction())
  }
}
