import cookie from 'js-cookie'
import { Api6Error, AppNetworkError } from 'api/api.types'
import { ApiParameters, details, features } from 'api/api.constants'
import { buildFullQuery } from 'functions/queryString'
import { consoleTime, consoleTimeEnd } from 'common/consoleLog'
import { setSentryTags, resolveLocale } from 'api/api.functions'
import { Cookies } from 'common-constants/cookie'
import { MAMBA_FEATURES } from 'common/constants'
import { resolveDeviceId } from 'functions/localStorage'
import { sendApiBtp } from 'functions/index'
import { API_6_BTP } from 'api/index'
import { NodeHeaders } from 'api/NodeHeaders'
import { captureException } from '@sentry/browser'
import { internalSessionInitApi } from './authorization/sessionInitApi'
import { allPaths } from 'api/fetchApiPaths'

const statusesWithoutJson = [204, 500]

export interface ApiData<T> {
  result?: T
  error?: Api6Error
  errorStatus?: number
}

export interface ApiResult<T = unknown> extends ApiData<T> {
  ok: boolean
  status: number
  headers: Headers
  /**
   * Для депрекейтед fetchApi из api/index.ts
   * Удалить вместе с ним
   */
  legacy?: boolean
}

// type Options = Partial<RequestInit & { headers?: NodeHeaders }>
type Options = RequestInit & { headers?: NodeHeaders }

const createLocaleQueryMap = (options: Options) => ({
  [ApiParameters.locale]: resolveLocale(
    (options.headers as unknown) as NodeHeaders
  ),
})

export const fetchQueryApi = <T, Q>(
  url: allPaths,
  query: Q,
  options: Options = {}
): Promise<ApiResult<T>> => {
  const fullQuery = buildFullQuery({
    ...query,
    ...createLocaleQueryMap(options),
  })

  return callApi((url + fullQuery) as allPaths, {
    ...options,
    method: 'GET',
  })
}

export const fetchApi = async <T>(
  url: allPaths,
  options: Options = {}
): Promise<ApiResult<T>> => {
  const query = buildFullQuery(createLocaleQueryMap(options))

  return callApi((url + query) as allPaths, {
    ...options,
    method: 'GET',
  })
}

export const postApi = async <T, B>(
  url: allPaths,
  body?: B,
  options: Options = {}
): Promise<ApiResult<T>> => {
  const query = buildFullQuery(createLocaleQueryMap(options))

  return callApi((url + query) as allPaths, {
    ...options,
    method: 'POST',
    body:
      process.env.browser && body instanceof FormData
        ? body
        : JSON.stringify(body),
  })
}

export const putApi = async <T, B>(
  url: allPaths,
  body?: B,
  options: Options = {}
): Promise<ApiResult<T>> => {
  const query = buildFullQuery(createLocaleQueryMap(options))

  return callApi((url + query) as allPaths, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

export const deleteApi = async <T, B>(
  url: allPaths,
  body?: B,
  options: Options = {}
): Promise<ApiResult<T>> => {
  const query = buildFullQuery(createLocaleQueryMap(options))

  return callApi((url + query) as allPaths, {
    ...options,
    method: 'DELETE',
    body: JSON.stringify(body),
  })
}

const callApi = async <T>(
  url: allPaths,
  options: Options = {}
): Promise<ApiResult<T>> => {
  const timerName = `fetch API6 ${String(url)} ${Math.round(
    1000 * Math.random()
  )}`
  consoleTime(timerName)
  // TODO пока закомментил для истории, после релиза удалю
  // const transaction = startTransaction({
  //   op: 'fetch v.2',
  //   name: `URL ${cleanUrlForSentry(url)}`,
  // })

  const browser = (process.env.browser as unknown) as boolean

  if (!browser && !options.headers?.['User-Agent']) {
    console.error(`Node headers should be defined! ${String(url)}`)
  }

  if (
    !browser &&
    options.method &&
    options.method !== 'GET' &&
    !options.headers?.['Csrf-Token']
  ) {
    console.error('Csrf-Token not found', url, options)
    throw new Error('Csrf-Token should be defined!')
  }

  try {
    options.credentials = 'same-origin'

    const headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      ...options.headers,
    })

    if (process.env.browser && options.body instanceof FormData) {
      headers.delete('Content-Type') // Браузер сам подставит заголовок и значение
    }

    if (browser) {
      /**
       * internalSessionInitApi не get запрос
       */
      if (options.method !== 'GET') {
        const sPost = cookie.get(Cookies.sPost) as string

        if (sPost) {
          headers.set('Csrf-Token', sPost)
        } else {
          captureException(new Error('Csrf-Token is empty'))

          await internalSessionInitApi()

          const sPost = cookie.get(Cookies.sPost)

          if (sPost) {
            headers.set('Csrf-Token', sPost)
          } else {
            captureException(
              new Error(
                'sPost is empty after update from Node. We change from sameSite=none to lax for safari old'
              )
            )
          }
        }
      }
      if (process.env.browser && window.API_6_CLIENT) {
        headers.set(
          'Mamba-Client',
          JSON.stringify({
            ...window.API_6_CLIENT,
            ...options.headers?.['Mamba-Client'],
          })
        )
      }

      /**
       * TODO объединить с другими
       * X-Requested-With в одну функцию
       */
      headers.set('X-Requested-With', 'XMLHttpRequest')
      headers.set('Mamba-Device-Id', resolveDeviceId()!)
    } else {
      // https://github.com/bitinn/node-fetch#fetch-options
      options.redirect = 'manual'

      // src/common/api/README.md
      if (options.method && options.method !== 'GET') {
        headers.set(
          'Csrf-Token',
          ((options as RequestInit).headers as HeadersInit & {
            'Csrf-Token': string
          })['Csrf-Token']
        )
      }
      headers.set('X-Node', 'true')
    }

    headers.set(MAMBA_FEATURES, JSON.stringify({ features, details }))

    const requestInit = options as RequestInit
    requestInit.headers = headers

    const promise = fetch(process.env.API_6_HOST + String(url), options)
    sendApiBtp(promise, API_6_BTP, url, options)
    const response = await promise

    setSentryTags(response)

    if (statusesWithoutJson.includes(response.status)) {
      consoleTimeEnd(timerName)
      // transaction.finish()

      /**
       * TODO:
       * Пока для нового апи
       * оставлю в 204 ответ result: response.ok
       * Нужно для Thunk операций внутри action
       */
      return {
        headers: response.headers,
        ok: response.ok,
        status: response.status,
        result: (response.ok as unknown) as T,
      }
    }

    const json = await response.json()
    consoleTimeEnd(timerName)
    // transaction.finish()

    return {
      result: json,
      headers: response.headers,
      ok: response.ok,
      status: response.status,
    }
  } catch (error) {
    consoleTimeEnd(timerName)
    // transaction.finish()
    console.error(`API6 ${String(url)}`, error)
    throw new AppNetworkError(error.message)
  }
}
