import React, { FC } from 'react'
import { createUniNoticeChannel } from 'functions/comet'
import { WebSocketChannelsSubscriber } from 'components/system/WebSocketChannelsSubscriber'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { webSocketReducer } from 'reducers/webSocketReducer'
import { useUserId } from 'hooks/useUserId'

export const SubscriptionWithCursor: FC = () => {
  useReducersInsert({ webSocket: webSocketReducer })
  const { cursorNewMessage } = useShallowEqualSelector(
    ({ webSocket: { cursorNewMessage } }) => ({
      cursorNewMessage,
    })
  )
  const userId = useUserId()

  if (!userId) {
    return null
  }

  return (
    <WebSocketChannelsSubscriber
      channels={[
        {
          channel: createUniNoticeChannel(userId),
          cursor: cursorNewMessage,
          min_size: 1,
          max_size: 10,
        },
      ]}
    />
  )
}
