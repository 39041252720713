import { STATE } from 'common/constants'
import { mutatePartialRootStateWithVipBeelineState } from 'components/page/VipBeeline/mutatePartialRootStateWithVipBeelineState'
import { mutatePartialRootStateWithVipMegafonState } from 'components/page/VipMegafon/mutatePartialRootStateWithVipMegafonState'
import { mutatePartialRootStateWithMnogoCardIdState } from 'hooks/useLinkUserToMnogoCard'
import { mutatePartialRootStateWithPromoCodeState } from 'reducers/promoCode/promoCodeReducer.functions'
import { loadStateFromGivenStorage } from './functions'
import { PartialRootState } from './store.types'
import { RootState } from 'reducers/RootState'

export const loadSessionStorageState = () => {
  return loadStateFromGivenStorage(sessionStorage)
}

export const saveStateToSessionStorage = (state: RootState) => {
  try {
    const partialState: PartialRootState = loadSessionStorageState()

    mutatePartialRootStateWithPromoCodeState(state, partialState)
    mutatePartialRootStateWithVipMegafonState(state, partialState)
    mutatePartialRootStateWithVipBeelineState(state, partialState)
    mutatePartialRootStateWithMnogoCardIdState(state, partialState)

    const serializedState = JSON.stringify(partialState)
    sessionStorage.setItem(STATE, serializedState)
  } catch (error) {
    // prettier-ignore
    console.error('Can\'t save state to session storage')
  }
}
