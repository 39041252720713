import { fetchApi } from 'api/fetchApi'
import { fetchInternalApi } from 'api/internal/fetchInternalApi'
import { sessionInitInternalPath } from 'api/internal/internal.constants'
import { NodeHeaders } from 'api/NodeHeaders'

export const sessionInitApi = (headers?: NodeHeaders) => {
  return fetchApi<void>('/session_init', { headers })
}

export const internalSessionInitApi = async (): Promise<
  ReturnType<typeof fetchInternalApi>
> => {
  return await fetchInternalApi(sessionInitInternalPath, {})
}
