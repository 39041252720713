import {
  fetchInviteFriendsDataAction,
  inviteFriendsHitListStatisticAction,
} from 'actions/inviteFriendPromoAction'
import { disableJavaScriptAction } from 'actions/system/systemAction'
import { SearchFriendParams } from 'components/paths'
import { insertReducers } from 'hooks/useReducersInsert'
import inviteFriendPromoReducer from 'reducers/inviteFriendPromoReducer'
import { InitialFetch } from 'server/InitialFetch'

export const fetchInviteUserComponentData: InitialFetch<SearchFriendParams> = async ({
  headers,
  locale,
  match,
  store,
  history,
}) => {
  const {
    authorizationReducer: { authorized },
  } = store.getState()

  const { isExact } = match!

  if (!authorized && isExact) {
    store.dispatch(disableJavaScriptAction())

    insertReducers(store, history, { inviteFriendPromoReducer })

    const inviteId = Number(match!.params.inviteId)
    const inviteIdToken = String(match!.params.token)
    const promises = [
      store.dispatch(
        inviteFriendsHitListStatisticAction(
          inviteId,
          inviteIdToken,
          headers,
          locale
        )
      ),
      store.dispatch(
        fetchInviteFriendsDataAction(inviteId, inviteIdToken, headers, locale)
      ),
    ]

    await Promise.all(promises)
  }
}
