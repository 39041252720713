import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const ProfileInviteFriendsAfterCallbackLoadable = loadable(
  () => import('./ProfileInviteFriendsAfterCallback'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error(
          'Profile Invite Friends After Callback split error',
          error
        )
      }
      return <DefaultLoader />
    })(defaultLoadableProps),
  }
)
