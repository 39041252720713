import {
  CREATE_BASE_URL,
  DISABLE_JAVASCRIPT,
  FETCH_FAKE_ADS,
  FETCH_LOCALE,
  FETCH_PARTNER_DATA,
  SET_DEFAULT_PAGE,
  SET_LANDING_PAGE,
  SET_META,
  SET_SHOW_ADBLOCK_BANNER,
  SWITCH_LANDING_TYPE,
  SystemActionTypes,
  UPDATE_CLIENT,
  UPDATE_COMMON_DATA,
  UPDATE_COOKIES_BANNER_RULES_BOTTOM,
  UPDATE_COOKIE_MOBILE_APP_BANNER,
  UPDATE_PARTNER,
  UPDATE_REFERRER,
  UPDATE_START_SCREEN,
  updateCookieMobileAppBannerAction,
} from 'actions/system/systemAction'
import {
  RESET_REFERRER,
  ResetReferrerAction,
} from 'actions/system/resetReferrerAction'
import { AcceptGdprAction } from 'actions/gdprAction'
import { FetchAbTestGroupListAction } from 'actions/abTestAction'
import {
  CHECK_BUNDLE_UPDATE,
  CheckBundleUpdateAction,
  setShouldAppReloadAction,
  SHOULD_APP_RELOAD,
} from 'actions/system/checkBundleUpdateAction'
import { cleanUrl } from 'functions/mergeAllUrls'
import {
  SET_STATUS_CODE,
  SHOW_NOT_FOUND,
  SystemRouteTypes,
} from 'actions/system/routeAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { LOGIN_ACTION, LoginAction } from 'actions/authorization/loginAction'
import {
  FeaturedPhotosPromoX3,
  initialState,
  ServicesInfo,
  SystemState,
} from 'reducers/system/SystemState'
import { Reducer } from 'redux'
import { CreateBaseUrlTypes } from 'actions/system/createBaseUrlAction'
import {
  UPDATE_META,
  UpdateDirectMetaAction,
} from 'actions/system/updateDirectMetaAction'
import {
  FETCH_META,
  FetchMetaDataAction,
} from 'actions/system/fetchMetaDataAction'
import { reduceClient, reduceLocale } from 'reducers/system/system.functions'
import { serviceWorkerPath } from 'common-constants/client-service.paths'
import {
  UPDATE_QUERY_ACTION,
  updateQueryAction,
} from 'actions/system/updateQueryAction'
import {
  updateMnogoCardIdAction,
  UPDATE_MNOGO_CARD_ID,
} from 'actions/system/updateMnogoCardIdAction'
import {
  restorePartialStateAction,
  RESTORE_PARTIAL_STATE,
} from 'actions/system/localStorageAction'
import { LOGOUT_ACTION, LogoutAction } from 'actions/authorization/logoutAction'
import {
  FETCH_SETTING_SERVICES_ENABLE,
  fetchSettingServicesEnableAction,
} from 'actions/system/fetchSettingServicesEnableAction'
import { isActionWithoutErrors } from 'reducers/isActionWithoutErrors'
import {
  FETCH_FEATURES,
  fetchFeaturesAction,
} from 'actions/system/fetchFeaturesAction'
import { parseFeaturesHexStrings } from 'functions/parseFeaturesHexStrings'
import { installMobileAppBannerCookie } from 'components/banner/InstallAppBanner/InstallAppBanner.constants'
import { ENABLE_REDESIGN, enableRedesign } from 'actions/system/enableRedesign'

export const systemReducer: Reducer<
  SystemState,
  | SystemActionTypes
  | SystemRouteTypes
  | CreateBaseUrlTypes
  | UpdateDirectMetaAction
  | CheckBundleUpdateAction
  | FetchMetaDataAction
  | LoginAction
  | LogoutAction
  | AcceptGdprAction
  | FetchAbTestGroupListAction
  | ResetReferrerAction
  | ReturnType<typeof setShouldAppReloadAction>
  | ReturnType<typeof updateQueryAction>
  | ReturnType<typeof updateMnogoCardIdAction>
  | ReturnType<typeof restorePartialStateAction>
  | ReturnType<typeof fetchSettingServicesEnableAction>
  | ReturnType<typeof fetchFeaturesAction>
  | ReturnType<typeof updateCookieMobileAppBannerAction>
  | ReturnType<typeof enableRedesign>
> = (state = initialState, action): SystemState => {
  switch (action.type) {
    case RESTORE_PARTIAL_STATE:
      return {
        ...state,
        ...action.partialState.systemReducer,
      }

    case DISABLE_JAVASCRIPT:
      return {
        ...state,
        javaScriptEnabled: false,
      }

    case UPDATE_START_SCREEN:
      return {
        ...state,
        startScreen: action.payload,
      }

    case LOGIN_ACTION:
      const { ready, result } = action
      if (ready) {
        return {
          ...state,
          startScreen: result ? result.startScreen : null,
        }
      } else {
        return state
      }

    case LOGOUT_ACTION:
      return defaultPromiseReducer(state, action, undefined, () => ({
        features: null,
        startScreen: null,
      }))

    case UPDATE_PARTNER:
      return {
        ...state,
        partner: action.partner,
      }

    case FETCH_META:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ metaLoading: true }),
        (meta) => {
          if (meta) {
            return { meta, metaLoading: false }
          }

          return null
        },
        () => ({ metaLoading: false })
      )

    case UPDATE_META:
      const { title, description, keywords } = action
      return {
        ...state,
        meta: { title, description: description, keywords },
      }

    case SET_META:
      return {
        ...state,
        staticMeta: action.meta,
      }

    case FETCH_FEATURES:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        return {
          features: parseFeaturesHexStrings(result.features, result.details),
        }
      })

    case FETCH_LOCALE:
      if (
        action.ready &&
        isActionWithoutErrors(action) &&
        !state.localeLocked
      ) {
        const code = action.result!.code
        return {
          ...state,
          ...reduceLocale(code),
          language: {
            ...action.result!,
          },
        }
      }

      return state

    case UPDATE_CLIENT:
      return {
        ...state,
        ...reduceClient(action.clientType),
      }

    case UPDATE_COMMON_DATA:
      // Проверим, что это не сервис воркер
      // https://redmine.mamba.ru/issues/120186
      if (action.common.referrer?.endsWith(serviceWorkerPath)) {
        action.common.referrer = ''
      }

      return {
        ...state,
        ...reduceLocale(action.common.language!.code!),
        ...action.common,
      }

    case SWITCH_LANDING_TYPE:
      return {
        ...state,
        landingType: action.landingType,
      }

    case UPDATE_COOKIES_BANNER_RULES_BOTTOM:
      return {
        ...state,
        cookies: { ...state.cookies, appBannerPopUpRulesCookie: true },
      }

    case UPDATE_COOKIE_MOBILE_APP_BANNER:
      return {
        ...state,
        cookies: { ...state.cookies, [installMobileAppBannerCookie]: true },
      }
    case SET_SHOW_ADBLOCK_BANNER:
      return {
        ...state,
        showAdblockBanner: action.value,
      }

    case UPDATE_MNOGO_CARD_ID:
      return {
        ...state,
        mnogoCardId: action.value,
      }

    case FETCH_FAKE_ADS:
      return defaultPromiseReducer(state, action, undefined, undefined, () => ({
        showAdblockBanner: true,
      }))

    case SET_LANDING_PAGE:
      return {
        ...state,
        isLandingPage: true,
      }

    case CREATE_BASE_URL:
      const newBaseUrl = cleanUrl(action.baseUrl)
      if (
        state.contextId !== action.id ||
        state.baseUrl !== newBaseUrl ||
        state.basePath !== action.basePath ||
        state.secondary !== action.secondary
      ) {
        return {
          ...state,
          contextId: action.id,
          baseUrl: newBaseUrl,
          basePath: action.basePath,
          secondary: action.secondary,
        }
      }
      return state

    case SET_DEFAULT_PAGE:
      return {
        ...state,
        isLandingPage: false,
      }

    case CHECK_BUNDLE_UPDATE:
      return defaultPromiseReducer(state, action, undefined, () => {
        if (action.result !== state.clientBundleName) {
          return { shouldReloadOnNextPush: true }
        }

        return null
      })

    case SHOULD_APP_RELOAD:
      return { ...state, shouldReloadOnNextPush: action.value }

    case SET_STATUS_CODE:
      return { ...state, statusCode: action.value }

    case SHOW_NOT_FOUND:
      return { ...state, showNotFound: action.value }

    case FETCH_PARTNER_DATA:
      return defaultPromiseReducer(state, action, undefined, (partner) => ({
        partner,
      }))

    case UPDATE_REFERRER:
      return { ...state, referrer: action.referrer }

    case RESET_REFERRER:
      return { ...state, referrer: '' }

    case UPDATE_QUERY_ACTION:
      return { ...state, query: { ...state.query, ...action.query } }

    case FETCH_SETTING_SERVICES_ENABLE:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        if (result) {
          /** ошибка бека в типах https://youtrack.mamba.ru/issue/M-6149 */
          const fixedResult = result as ServicesInfo & {
            feturedPhotosPromoX3?: FeaturedPhotosPromoX3
          }

          const featuredPhotosPromoX3 = fixedResult.feturedPhotosPromoX3
          delete fixedResult.feturedPhotosPromoX3

          fixedResult.featuredPhotosPromoX3 = featuredPhotosPromoX3

          return {
            ...state,
            services: fixedResult,
          }
        }

        return state
      })

    case ENABLE_REDESIGN:
      return { ...state, redesign: true }

    default:
      return state
  }
}
