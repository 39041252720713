import { PartialRootState } from 'common/store/store.types'

import { RootState } from 'reducers/RootState'

/** Подготавливает объект для сохранения в sessionStorage */
export const mutatePartialRootStateWithPromoCodeState = (
  reducerState: RootState,
  partialRootState: PartialRootState
): void => {
  if (reducerState.promoCode) {
    partialRootState.promoCode = {
      aboutCode: reducerState.promoCode.aboutCode,
    }
  }
}
