import {
  FETCH_INVITE_FRIEND_DATA,
  FetchInviteFriendsPromoActionTypes,
} from 'actions/inviteFriendPromoAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

export interface inviteFriendPromoState {
  inviterName: string
  inviteeName: string
  todayMatches: number
  usersPicturesList: string[]
  usersPicturesListLoaded: boolean
  notFound: boolean
}

const inviteFriendPromoReducer = (
  state: inviteFriendPromoState = {
    inviterName: '',
    inviteeName: '',
    todayMatches: 0,
    usersPicturesList: [],
    usersPicturesListLoaded: false,
    notFound: false,
  },
  action: FetchInviteFriendsPromoActionTypes
) => {
  switch (action.type) {
    case FETCH_INVITE_FRIEND_DATA:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          usersPicturesListLoaded: false,
          notFound: false,
        }),
        ({ inviterName, inviteeName, todayMatches, users }) => ({
          inviterName,
          inviteeName,
          todayMatches,
          usersPicturesList: users.map(
            (userObject: { photoUrl: string }) => userObject.photoUrl
          ),
          usersPicturesListLoaded: true,
        }),
        ({ errorStatus }: any) => ({ notFound: errorStatus === 404 })
      )

    default:
      return state
  }
}

export default inviteFriendPromoReducer
