import React, { FC, SVGProps } from 'react'

export const LoaderSvg: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid meet"
    width="80"
    height="80"
    viewBox="0 0 600 600"
    {...props}
  >
    <defs>
      <clipPath id="_12121_R_G_L_1_C_0">
        <path
          id="_12121_R_G_L_1_C_0_P_0"
          fillRule="nonzero"
          d="M299.38 1 C133.97,1 -0.12,135.09 -0.12,300.5 C-0.12,465.91 133.97,600 299.38,600 C464.78,600 598.88,465.91 598.88,300.5 C598.88,135.09 464.78,1 299.38,1z "
        />
      </clipPath>
      <animateMotion
        repeatCount="indefinite"
        dur="1s"
        begin="0s"
        xlinkHref="#_R_G_L_1_G_G_0_L_0_G"
        fill="freeze"
        keyTimes="0;1"
        path="M-364 370 C-364,370 83,370 83,370 "
        keyPoints="0;1"
        keySplines="0.857 0 0.177 1"
        calcMode="spline"
      />
      <animateTransform
        repeatCount="indefinite"
        dur="1s"
        begin="0s"
        xlinkHref="#_R_G_L_1_G_G_0_L_0_G"
        fill="freeze"
        attributeName="transform"
        from="7.11 7.11"
        to="7.11 7.11"
        type="scale"
        additive="sum"
        keyTimes="0;1"
        values="7.11 7.11;7.11 7.11"
        keySplines="0 0 1 1"
        calcMode="spline"
      />
      <animateTransform
        repeatCount="indefinite"
        dur="1s"
        begin="0s"
        xlinkHref="#_R_G_L_1_G_G_0_L_0_G"
        fill="freeze"
        attributeName="transform"
        from="-186.5 -35.5"
        to="-186.5 -35.5"
        type="translate"
        additive="sum"
        keyTimes="0;1"
        values="-186.5 -35.5;-186.5 -35.5"
        keySplines="0 0 1 1"
        calcMode="spline"
      />
      <animate
        attributeType="XML"
        attributeName="opacity"
        dur="1s"
        from="0"
        to="1"
        xlinkHref="#time_group"
      />
    </defs>
    <g id="_R_G">
      <g
        id="_R_G_L_2_G"
        transform=" translate(299.375, 300) scale(2.70815, 2.70815) translate(-13.5, -74.5)"
      >
        <path
          id="_R_G_L_2_G_D_0_P_0"
          fill="#ffffff"
          fillOpacity="0.24"
          fillRule="nonzero"
          d=" M13.5 -36 C-47.53,-36 -97,13.47 -97,74.5 C-97,135.53 -47.53,185 13.5,185 C74.53,185 124,135.53 124,74.5 C124,13.47 74.53,-36 13.5,-36z "
        />
      </g>
      <g
        id="_R_G_L_1_G"
        transform=" translate(300.54, 299.52) scale(0.95985, 0.95985) translate(-300, -300)"
      >
        <g clipPath="url(#_12121_R_G_L_1_C_0)">
          <g id="_R_G_L_1_G_G_0_L_0_G">
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_0_P_0"
              fill="#ffded0"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M164.74 6.28 C164.74,6.28 174.95,10.04 181.19,17.22 C181.19,17.22 189.17,26.71 177.45,35.87 C177.45,35.87 176.28,36.66 176.28,34.91 C176.28,33.16 176.63,31.09 172.86,28.31 C169.1,25.53 166.3,22.63 162.28,20.57 C162.28,20.57 160.27,19.73 161.42,13.38 C162.57,7.04 164.74,6.28 164.74,6.28z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_1_P_0"
              fill="#f88ac9"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M164.74 6.28 C164.74,6.28 155.67,-5.44 141.64,4.44 C127.62,14.32 125.91,25.65 126.18,33.03 C126.46,40.29 128.87,51.17 150.54,69.21 C151.26,69.81 152.18,70.13 153.12,70.13 C153.12,70.13 172.73,69.94 172.73,69.94 C173.73,69.93 174.14,68.6 173.32,68.03 C164.39,61.88 137.63,41.87 143.73,26.68 C144.07,25.37 145.36,24.54 146.69,24.79 C150.76,25.55 159.45,26.4 162.28,20.57 C162.28,20.57 164.74,6.28 164.74,6.28z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_2_P_0"
              fill="#8051fb"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M164.76 6.33 C164.76,6.33 153.69,4.53 151.96,8.42 C150.22,12.31 156.57,14.04 159.04,18.05 C161.51,22.07 162.28,20.57 162.28,20.57 C162.28,20.57 164.76,6.33 164.76,6.33z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_3_P_0"
              fill="#2e2a29"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M155.85 11.91 C157,11.91 157.92,10.98 157.92,9.83 C157.92,8.69 156.98,7.76 155.84,7.76 C154.69,7.77 153.77,8.7 153.77,9.84 C153.77,10.99 154.71,11.91 155.85,11.91z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_4_P_0"
              fill="#2e2a29"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M181.9 18.24 C179.19,24.54 174.36,27.01 172.24,27.84 C172.45,28 172.65,28.15 172.86,28.32 C176.63,31.09 176.27,33.16 176.28,34.91 C176.28,36.67 177.45,35.88 177.45,35.88 C186.88,28.52 183.57,20.94 181.91,18.25 C181.91,18.25 181.9,18.24 181.9,18.24z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_5_P_0"
              fill="#ffded0"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M227.54 6.28 C227.54,6.28 237.75,10.04 243.98,17.22 C243.98,17.22 251.97,26.71 240.25,35.87 C240.25,35.87 239.08,36.66 239.08,34.91 C239.07,33.16 239.42,31.09 235.66,28.31 C231.9,25.53 229.1,22.63 225.08,20.57 C225.08,20.57 223.07,19.73 224.22,13.38 C225.37,7.04 227.54,6.28 227.54,6.28z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_6_P_0"
              fill="#f88ac9"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M227.54 6.28 C227.54,6.28 218.47,-5.44 204.44,4.44 C190.42,14.32 188.71,25.65 188.98,33.03 C189.26,40.29 191.67,51.17 213.34,69.21 C214.06,69.81 214.98,70.13 215.92,70.13 C215.92,70.13 235.53,69.94 235.53,69.94 C236.53,69.93 236.94,68.6 236.11,68.03 C227.19,61.88 200.43,41.87 206.53,26.68 C206.87,25.37 208.16,24.54 209.49,24.79 C213.56,25.55 222.25,26.4 225.08,20.57 C225.08,20.57 227.54,6.28 227.54,6.28z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_7_P_0"
              fill="#8051fb"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M227.56 6.33 C227.56,6.33 216.49,4.53 214.76,8.42 C213.02,12.31 219.37,14.04 221.84,18.05 C224.31,22.07 225.08,20.57 225.08,20.57 C225.08,20.57 227.56,6.33 227.56,6.33z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_8_P_0"
              fill="#2e2a29"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M218.65 11.91 C219.8,11.91 220.72,10.98 220.72,9.83 C220.72,8.69 219.78,7.76 218.64,7.76 C217.49,7.77 216.57,8.7 216.57,9.84 C216.57,10.99 217.5,11.91 218.65,11.91z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_9_P_0"
              fill="#2e2a29"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M244.7 18.24 C241.99,24.54 237.16,27.01 235.04,27.84 C235.25,28 235.45,28.15 235.66,28.32 C239.42,31.09 239.07,33.16 239.08,34.91 C239.08,36.67 240.25,35.88 240.25,35.88 C249.68,28.52 246.37,20.94 244.71,18.25 C244.71,18.25 244.7,18.24 244.7,18.24z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_10_P_0"
              fill="#ffded0"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M290.34 6.28 C290.34,6.28 300.55,10.04 306.78,17.22 C306.78,17.22 314.77,26.71 303.05,35.87 C303.05,35.87 301.88,36.66 301.88,34.91 C301.87,33.16 302.22,31.09 298.46,28.31 C294.7,25.53 291.9,22.63 287.88,20.57 C287.88,20.57 285.87,19.73 287.02,13.38 C288.17,7.04 290.34,6.28 290.34,6.28z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_11_P_0"
              fill="#f88ac9"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M290.34 6.28 C290.34,6.28 281.27,-5.44 267.24,4.44 C253.22,14.32 251.51,25.65 251.78,33.03 C252.06,40.29 254.47,51.17 276.14,69.21 C276.86,69.81 277.78,70.13 278.72,70.13 C278.72,70.13 298.33,69.94 298.33,69.94 C299.33,69.93 299.74,68.6 298.91,68.03 C289.99,61.88 263.23,41.87 269.33,26.68 C269.67,25.37 270.96,24.54 272.29,24.79 C276.36,25.55 285.05,26.4 287.88,20.57 C287.88,20.57 290.34,6.28 290.34,6.28z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_12_P_0"
              fill="#8051fb"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M290.35 6.33 C290.35,6.33 279.29,4.53 277.56,8.42 C275.82,12.31 282.17,14.04 284.64,18.05 C287.11,22.07 287.88,20.57 287.88,20.57 C287.88,20.57 290.35,6.33 290.35,6.33z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_13_P_0"
              fill="#2e2a29"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M281.45 11.91 C282.6,11.91 283.52,10.98 283.52,9.83 C283.52,8.69 282.58,7.76 281.44,7.76 C280.29,7.77 279.37,8.7 279.37,9.84 C279.37,10.99 280.3,11.91 281.45,11.91z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_14_P_0"
              fill="#2e2a29"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M307.5 18.24 C304.79,24.54 299.96,27.01 297.84,27.84 C298.05,28 298.25,28.15 298.46,28.32 C302.22,31.09 301.87,33.16 301.88,34.91 C301.88,36.67 303.05,35.88 303.05,35.88 C312.47,28.52 309.16,20.94 307.5,18.25 C307.5,18.25 307.5,18.24 307.5,18.24z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_15_P_0"
              fill="#ffded0"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M353.14 6.28 C353.14,6.28 363.35,10.04 369.58,17.22 C369.58,17.22 377.57,26.71 365.85,35.87 C365.85,35.87 364.68,36.66 364.68,34.91 C364.67,33.16 365.02,31.09 361.26,28.31 C357.5,25.53 354.7,22.63 350.68,20.57 C350.68,20.57 348.67,19.73 349.82,13.38 C350.97,7.04 353.14,6.28 353.14,6.28z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_16_P_0"
              fill="#f88ac9"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M353.14 6.28 C353.14,6.28 344.07,-5.44 330.04,4.44 C316.02,14.32 314.31,25.65 314.58,33.03 C314.86,40.29 317.27,51.17 338.94,69.21 C339.66,69.81 340.58,70.13 341.52,70.13 C341.52,70.13 361.13,69.94 361.13,69.94 C362.13,69.93 362.54,68.6 361.71,68.03 C352.79,61.88 326.03,41.87 332.13,26.68 C332.47,25.37 333.76,24.54 335.09,24.79 C339.16,25.55 347.85,26.4 350.68,20.57 C350.68,20.57 353.14,6.28 353.14,6.28z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_17_P_0"
              fill="#8051fb"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M353.16 6.33 C353.16,6.33 342.09,4.53 340.36,8.42 C338.62,12.31 344.97,14.04 347.44,18.05 C349.91,22.07 350.68,20.57 350.68,20.57 C350.68,20.57 353.16,6.33 353.16,6.33z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_18_P_0"
              fill="#2e2a29"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M344.25 11.91 C345.4,11.91 346.32,10.98 346.32,9.83 C346.31,8.69 345.38,7.76 344.24,7.76 C343.09,7.77 342.17,8.7 342.17,9.84 C342.17,10.99 343.1,11.91 344.25,11.91z "
            />
            <path
              id="_R_G_L_1_G_G_0_L_0_G_D_19_P_0"
              fill="#2e2a29"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M370.3 18.24 C367.59,24.54 362.76,27.01 360.64,27.84 C360.85,28 361.05,28.15 361.26,28.32 C365.02,31.09 364.67,33.16 364.68,34.91 C364.68,36.67 365.85,35.88 365.85,35.88 C375.28,28.52 371.97,20.94 370.3,18.25 C370.3,18.25 370.3,18.24 370.3,18.24z "
            />
          </g>
        </g>
      </g>
      <g
        id="_R_G_L_0_G"
        transform=" translate(300, 300) scale(2.64706, 2.64706) translate(-13.5, -74.5)"
      >
        <path
          id="_R_G_L_0_G_D_0_P_0"
          stroke="#7f7472"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          strokeWidth="5"
          strokeOpacity="0.16"
          d=" M13.25 -36 C-47.93,-36 -97.53,13.47 -97.53,74.5 C-97.53,135.53 -47.93,185 13.25,185 C74.43,185 124.03,135.53 124.03,74.5 C124.03,13.47 74.43,-36 13.25,-36z "
        />
      </g>
    </g>
    <g id="time_group" />
  </svg>
)
