export enum StorefrontService {
  photoLine = 'photoline',
  featuredPhotos = 'featuredPhotos',
  featuredRouteParamsPhotos = 'featured-photos', // чтобы не сломать остальное, добавлю отдельный параметр для сравнения с route-params
  makeTop = 'maketop',
  gifts = 'gifts',
  topUp = 'topup',
  vip = 'vip',
  vipPresent = 'vipPresent',
  vipSubscription = 'vipSub',
}

export enum PaymentType {
  BankCards = 'BankCards',
  Mobile = 'Mobile',
  YandexMoney = 'YandexMoney',
  YandexPay = 'YandexPay',
  YooKassa = 'YooKassa',
  QiwiWallet = 'QiwiWallet',
  Terminals = 'Terminals',
  PromoCode = 'PromoCode',
  Coins = 'Coins',
  Sms = 'SMS', // Должен быть, судя по урлу
  Free = 'free', // Бесплатные подарки
  Paypal = 'Paypal',
  Webmoney = 'Webmoney',
  PaymentWall = 'PaymentWall',
  AliPay = 'AliPay',
  UnionPay = 'UnionPay',
  Boleto = 'Boleto',
  SPB = 'SbpNspk',
}

export const IframeMethodType = 'UrlForIframe'
export const MobilePhoneMethodType = 'MobilePhone'
export const SmsMethodType = 'SMS'
export const UrlForRedirectMethodType = 'UrlForRedirect'
export const PaymentRequestMethodType = 'PaymentRequest'
export const TopupMethodType = 'Topup'

// Вроде как отдельного нет, он тоже входит в редирект по урлу
export const PaypalType = 'Paypal'

export const MambaCurrency = 'MAM'

export const FREE_TARIFF = 0

export const marginItem = 15

export const callerParameter = 'caller'

export enum CallerType {
  'event_visitor' = 'Events.visitors.card.vip',
}
