import {
  hideOrRevealAgeApi,
  hideOrRevealLastAccessTimeApi,
  incomingMessagesSoundApi,
  switchAtlasApi,
  switchIncognitoApi,
  switchInvisibleModeApi,
  toggleEroticPhotosApi,
  updateInviterStatusApi,
} from 'api/settings/settingsApi'
import {
  Fields,
  SingleSelectFieldsSettings,
} from 'components/page/Settings/Fields'

export const findToggleApiMethod = (field: string, enabled: boolean) => {
  switch (field) {
    case 'eroticPhotos':
      return toggleEroticPhotosApi(enabled)
    case Fields.MessageSound:
      return incomingMessagesSoundApi(enabled)
    case SingleSelectFieldsSettings.HiddenLastAccessTime:
      return hideOrRevealLastAccessTimeApi(enabled)
    case SingleSelectFieldsSettings.HiddenAge:
      return hideOrRevealAgeApi(enabled)
    case SingleSelectFieldsSettings.InvisibleMode:
      return switchInvisibleModeApi(enabled)
    case SingleSelectFieldsSettings.InviterStatusField:
      return updateInviterStatusApi(enabled)
    case SingleSelectFieldsSettings.Incognito:
      return switchIncognitoApi(enabled)
    case 'atlas':
      return switchAtlasApi(enabled)
  }
  throw new Error(`Can't find toggle API method for field "${field}"`)
}
