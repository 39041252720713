/**
 * В задачах номер фичи обычно указывается в decimal
 * На сервер передаем значение в hexadecimal
 * Онлайн конвертер: https://www.rapidtables.com/convert/number/decimal-to-hex.html
 */
export const mambaFeaturesFlags = {
  featuresManagement: '0001', // 1
  searchFaceCoords: '000C', // 12
  searchAccessToAdditionalFields: '000A', // 10
  signWithApple: '000F', // 15
  uploadPhotoWithStatusPending: '000E', // 14
  filterGenderStream: '0003', // 3
  messengerNotices: '0010', // 16
  authorizationByMessenger: '0018', // 24
  giftsFromDeletedUsers: '001F', // 31
  hitListLikersRevealed: '0020', // 32
  chatBetweenBlockedUserAndSupport: '0023', // 35
  outsideContactsAvailability: '0024', // 36
  sameSiteNoneSecureCookie: '0025', // 37
  ratingHeightSearchRestricted: '0027', // 39 - Поддержка ограничений фильтров в фоторейтинге (#m956)
  lookForAgeRangeMax: '0029', // 41
  lgbt: '002A', // 42
  newGoals: '002B',
  lgbtExtended: '002D', // 45 - https://youtrack.mamba.ru/issue/M-6235
  ednaSupport: '002E', // 46
} as const

/** Список mambaFeaturesFlags с конвертированными значениями hex -> int */
export const mambaFeaturesFlagsParsed = Object.entries(
  mambaFeaturesFlags
).reduce((acc, [key, value]) => {
  acc[key] = parseInt(value, 16)
  return acc
}, {} as Readonly<Record<keyof typeof mambaFeaturesFlags, number>>)

export const features =
  mambaFeaturesFlags.featuresManagement +
  mambaFeaturesFlags.searchAccessToAdditionalFields +
  mambaFeaturesFlags.searchFaceCoords +
  mambaFeaturesFlags.sameSiteNoneSecureCookie +
  mambaFeaturesFlags.signWithApple +
  mambaFeaturesFlags.filterGenderStream +
  mambaFeaturesFlags.messengerNotices +
  mambaFeaturesFlags.authorizationByMessenger +
  mambaFeaturesFlags.giftsFromDeletedUsers +
  mambaFeaturesFlags.hitListLikersRevealed +
  mambaFeaturesFlags.chatBetweenBlockedUserAndSupport +
  mambaFeaturesFlags.outsideContactsAvailability +
  mambaFeaturesFlags.ratingHeightSearchRestricted +
  mambaFeaturesFlags.lookForAgeRangeMax +
  mambaFeaturesFlags.lgbt +
  mambaFeaturesFlags.newGoals +
  mambaFeaturesFlags.lgbtExtended +
  mambaFeaturesFlags.ednaSupport

export const localeParameter = '_loc[locale]' as const
export const CATCH_NETWORK_ERROR = 'CATCH_NETWORK_ERROR' as const

enum MambaFeaturesDetail {
  /**
   * Включает возможность регистрации/авторизации через telegram, whatsup и viber
   * https://redmine.mamba.ru/issues/101273#note-35
   */
  TelegramWhatsAppViber = '00180007',
  /**
   * Строка 002A0002 состоит из:
   * 002A - значение 42, это фича - Поддержка скрытия/модификации гейского функционала (#m5062)
   * 0002 - значение 2, это вариант фичи:
   *    вариант 0 – поля отображаются и ведут себя как раньше – для пользователей не из РФ
   *    вариант 1 – поля отображаются, но формулировки изменены + скрыто поле «Ориентация» (?) – для пользователей из РФ
   *    вариант 2 – поля НЕ отображаются – для пользователей из РФ в случае необходимости убрать поля из приложений (не факт, что до этого дойдет)
   * Вариант фичи нужен для того, чтобы клиент мог сказать, какой максимальный вариант фичи он умеет поддерживать.
   * Т.е. это нужно для будущего увеличения вариантов фичи.
   */
  Lgbt = '002A0002',
}

export const details =
  MambaFeaturesDetail.TelegramWhatsAppViber + MambaFeaturesDetail.Lgbt

export enum Api5ClientType {
  web = 17,
}

export enum ApiParameters {
  locale = '_loc[locale]',
}

export enum ProfileState {
  blocked = 'blocked',
  deleted = 'deleted',
  active = 'active',
}
