import { pushModalAction } from 'actions/route/routeAction'
import { fetchAskPhotoNeededAction } from 'actions/rating/checkIfAskPhotoNeededAction'
import {
  albumPath,
  uploadPhotosPath,
} from 'components/page/UploaderPhotos/Uploader.paths'
import { usePrevious } from 'hooks/usePrevious'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from '../useShallowEqualSelector'
import { useLayoutContext } from 'hooks/useLayoutContext'
import {
  findMatchUploader,
  isRouteIncludedInAbTest,
  isToSoonToShowPhotoUploader,
  setNextTimeToShowPhotoUploader,
} from 'hooks/useShowPhotoUploader/useShowPhotoUploader.functions'
import { isPureDevMode } from 'functions/isPureDevMode'

/**
 * Показывает фотоаплоадер в рамках AB-тестирования.
 *
 * Хук специально не разносит логику по редюсерам,
 * чтобы после завершение АВ-тестирования,
 * можно было легко удалить весь связанный код.
 */
export const useShowPhotoUploader = () => {
  const dispatch = useDispatch()
  const { baseUrl } = useLayoutContext()
  const { authorized, userId, pathname } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      userReducer: { userId },
      router: {
        location: { pathname },
      },
    }) => ({
      authorized,
      userId,
      pathname,
    })
  )

  const prevPathname = usePrevious(pathname)
  const [timerId, setTimerId] = useState<number>(0)
  const match = findMatchUploader(pathname, baseUrl)

  const openModalUpload = async () => {
    /**
     * Если загрузились.
     * Время еще не прошло.
     * Правильный Route.
     * Еще неоткрыт загрузчик.
     * Предыдущий url тоже не был загрузчиком,
     * и не открыт modal
     * Последний когда открыты другие модалы на правильных Route.
     */
    if (
      userId &&
      !isToSoonToShowPhotoUploader(userId) &&
      isRouteIncludedInAbTest(pathname) &&
      !match &&
      !findMatchUploader(prevPathname, baseUrl) &&
      timerId &&
      !isPureDevMode()
    ) {
      try {
        const { result } = await dispatch(fetchAskPhotoNeededAction())

        /** Сохраним в localStorage дату, до которой нам не нужно спрашивать показывать ли фотоаплоадер */
        if (result?.timeRepeat) {
          setNextTimeToShowPhotoUploader(userId, result.timeRepeat)
        }

        /** Сервер сказал, что не нужно показывать аплоадер */
        if (!result?.showUploader) {
          return
        }

        dispatch(pushModalAction(uploadPhotosPath, albumPath))
      } catch (error) {
        console.error('Не удалось открыть модал загрузки фото')
      }
    }

    clearTimeout(timerId)
  }

  useEffect(() => {
    if (!authorized) {
      return
    }

    if (timerId !== 0 && !match) {
      clearTimeout(timerId)
      setTimerId(0)
    }

    setTimerId(window.setTimeout(openModalUpload, 500))
  }, [dispatch, userId, pathname])
}
