import { ProfileInviteFriendsAfterCallbackLoadable } from 'components/page/ProfileInviteFriends/ProfileInviteFriendsAfterCallbackLoadable'
import { profileInviteCallbackPath } from 'components/page/ProfileInviteFriends/ProfileInviteFriends.paths'
import { AppRoute } from 'components/components.types'

export const profileInviteFriendsAfterCallbackRoute: AppRoute[] = [
  {
    path: profileInviteCallbackPath,
    component: ProfileInviteFriendsAfterCallbackLoadable,
  },
]
