export const BOTTOM_SHEET_KEY = 'data-bottom-sheet'
export const BOTTOM_SHEET_VALUE = 'open'

export const bottomSheetGlobalCss = `
  [${BOTTOM_SHEET_KEY}="${BOTTOM_SHEET_VALUE}"] {
    overscroll-behavior: none                                                               
  }

  [${BOTTOM_SHEET_KEY}="${BOTTOM_SHEET_VALUE}"] body {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    touch-action:none;
    overscroll-behavior: none                                                               
  }
`

export const BOTTOM_SHEET_NODE_PORTAL_ID = 'bottom-sheet-portal'
