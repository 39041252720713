import { formReducer } from './formReducer'
import { authorizationReducer } from './authorizationReducer'
import { errorReducer } from './error/errorReducer'
import { systemReducer } from 'reducers/system/systemReducer'
import { textReducer } from './textReducer'
import { captchaReducer } from './captcha/captchaReducer'
import { hitListReducer } from './hitListReducer'
import { photoReducer } from './photo/photoReducer'
import { userReducer } from './user/userReducer'
import { tooltipReducer } from './tooltipReducer'
import { photoLineReducer } from './photoLine/photoLineReducer'
import { paymentStorefrontReducer } from './storefront/paymentStorefrontReducer'
import { complaintReducer } from './complaintReducer'
import { supportFormReducer } from './supportFormReducer'
import { menuSideBarMailruReducer } from './menuSideBarMailruReducer'
import popularityReducer from './popularityReducer'
import { photoRatingReducer } from './photoRatingReducer'
import cashOutStreamReducer from './cashOutStreamReducer'
import { landingReducer } from './landing/landingReducer'
import { socialUserReducer } from './socialUserReducer'
import chatReducer from './chatReducer'
import { interestsReducer } from './interests/interestsReducer'
import { photoViewerReducer } from 'reducers/photo/photoViewerReducer'
import { abTestReducer } from 'reducers/system/abTestReducer'
import { photoAlbumReducer } from 'reducers/photo/photoAlbumReducer'
import { deleteProfileReducer } from 'reducers/deleteProfileReducer'
import { metaDataReducer } from 'reducers/metaData/metaDataReducer'
import { noticeShortCut } from 'reducers/notice/noticeShortCut'
import { feedReducer } from './feed/feedReducer'
import { noticeEmails } from './noticeEmails/noticeEmails'
import { locationsHistory } from './locationsHistory/locationsHistoryReducer'
import { metricReducer } from 'reducers/metric/metric'
import { breadCrumbsReducer } from 'reducers/seo/breadCrumbs'
import { locationAutoComplete } from 'reducers/locationAutoComplete/locationAutoComplete'
import { promoCodeReducer } from './promoCode/promoCodeReducer'
import { birthDateReducer } from 'reducers/user/field/birthDate'
import { vipMegafonReducer } from './vipMegafon/vipMegafonReducer'
import { vipBeelineReducer } from './vipBeeline/vipBeelineReducer'
import { photoRatingSettingsReducer } from 'reducers/rating/photoRatingSettingsReducer'
import { pushNotificationsReducer } from './pushNotifications/pushNotificationsReducer'
import { photoLineStoreFrontReducer } from 'reducers/storefront/photoLineStoreFrontReducer'
import storefrontReducer from 'reducers/storefrontReducer'
import { profileReducer } from 'reducers/profile/profileReducer'
import { contactListReducer } from 'reducers/contactList/contactListReducer'
import { messengerRequestsReducer } from './messengerRequests/messengerRequestsReducer'
import { promoContactListReducer } from 'reducers/banner/promoContactListReducer'
import { topUpStorefrontReducer } from 'reducers/storefront/topUpStorefrontReducer'
import { draftMessagesReducer } from 'reducers/draftMessages/draftMessagesReducer'
import { promoReducer } from 'reducers/promo/promoReducer'
import { uniNoticeReducer } from 'reducers/uniNoticeReducer'
import { geolocationReducer } from './geolocation/geolocationReducer'
import { locationReducer } from './location/location'
import { miscellaneousReducer } from './miscellaneous/miscellaneousReducer'
import { lgbtDataReducer } from './lgbtData/lgbtDataReducer'

const asyncReducerDummy = (state = {}) => state

export const staticReducers = {
  formReducer,
  metaData: metaDataReducer,
  abTest: abTestReducer,
  promo: promoReducer,
  authorizationReducer,
  errorReducer,
  systemReducer,
  textReducer,
  captchaReducer,
  photoReducer,
  tooltipReducer,
  photoLineReducer,
  complaintReducer,
  userReducer,
  supportFormReducer,
  menuSideBarMailruReducer,
  popularityReducer,
  cashOutStreamReducer,
  noticeShortCut,
  landingReducer,
  socialUserReducer,
  chatReducer,
  interestsReducer,
  photoViewerReducer,
  profileGift: asyncReducerDummy,
  photoAlbumReducer: asyncReducerDummy,
  inviteFriendPromoReducer: asyncReducerDummy,
  deleteProfileReducer,
  boardingReducer: asyncReducerDummy,
  hitList: hitListReducer,
  locationsHistory,
  promoCode: promoCodeReducer,
  pushNotifications: pushNotificationsReducer,
  geolocation: geolocationReducer,
  location: locationReducer,
  miscellaneous: miscellaneousReducer,
}

export interface RootReducers {
  clientStatistic?: Function
  abTest?: Function
  token?: Function
  webSocket?: Function
  vipStoreFront?: Function
  makeTopStoreFront?: Function
  systemView?: Function
  hitList?: Function
  vipPresentStoreFront?: Function

  /** @deprecated вообще должен быть выпилен */
  storefrontReducer?: typeof storefrontReducer
  stepRegistration?: Function
  profileGift?: Function
  featuredPhotosStoreFront?: Function
  uploaderPhotos?: Function
  photosAlbumAttach?: Function
  comment?: Function
  commentPhoto?: Function
  emoji?: Function
  sharingContent?: Function
  sticker?: Function
  relationship?: Function
  foreignProfile?: Function
  foreignProfilePhotos?: Function
  foreignProfileInterests?: Function
  profileMenu?: Function
  settingsFieldEmail?: Function
  searchListProfile?: Function
  geolocation?: Function
  inviteFriends?: Function
  // TODO переименовать все с окончанием Reducer
  downloadAppReducer?: Function
  menuSideBarMailruReducer?: typeof menuSideBarMailruReducer
  interestsReducer?: typeof interestsReducer
  complaintReducer?: typeof complaintReducer
  notificationReducer?: Function
  landingReducer?: typeof landingReducer
  socialUserReducer?: Function
  bannerReducer?: Function
  promo?: typeof promoReducer
  formReducer?: typeof formReducer
  datingProfileReducer?: Function
  errorReducer?: Function
  userReducer?: Function
  supportFormReducer?: Function
  storeFrontGifts?: Function
  settingsReducer?: Function // typeof settingsReducer
  photoReducer?: typeof photoReducer
  photoLineReducer?: Function
  chatMessengerReducer?: Function
  messengerRequests?: typeof messengerRequestsReducer
  mainSearchReducer?: Function
  photosProfileReducer?: Function
  paymentStorefrontReducer?: typeof paymentStorefrontReducer
  topUpStorefrontReducer?: typeof topUpStorefrontReducer
  photoAlbumReducer?: typeof photoAlbumReducer
  inviteFriendPromoReducer?: Function
  featuredPhotosPopularity?: Function
  deleteProfile?: Function
  boarding?: Function
  teamo?: Function
  horoscope?: Function
  astroStar?: Function
  counterDown?: Function
  profileDelete?: Function
  profileHappyStory?: Function
  countriesInfo?: Function
  promoServiceAvailable?: Function
  uniNoticeReducer?: typeof uniNoticeReducer
  feed?: typeof feedReducer
  textReducer?: typeof textReducer
  noticeEmails?: typeof noticeEmails
  locationsHistory?: typeof locationsHistory
  metric?: typeof metricReducer
  breadCrumbs?: typeof breadCrumbsReducer
  locationAutoComplete?: typeof locationAutoComplete
  promoCode?: typeof promoCodeReducer
  birthDate?: typeof birthDateReducer
  vipMegafon?: typeof vipMegafonReducer
  vipBeeline?: typeof vipBeelineReducer
  photoRatingReducer?: typeof photoRatingReducer
  photoRatingSettings?: typeof photoRatingSettingsReducer
  pushNotifications?: typeof pushNotificationsReducer
  photoLineStoreFront?: typeof photoLineStoreFrontReducer
  profile?: typeof profileReducer
  contactList?: typeof contactListReducer
  bannerContactList?: typeof promoContactListReducer
  captchaReducer?: typeof captchaReducer
  draftMessages?: typeof draftMessagesReducer
  location?: typeof locationReducer
  miscellaneous?: typeof miscellaneousReducer
  lgbtData?: typeof lgbtDataReducer
}
