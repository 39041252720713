import { thenPartPath } from 'components/block/Then/Then.paths'
import { searchSettingsPath } from 'components/page/Search/Search.paths'
import { stepRegistrationInnerArrayPaths } from 'components/page/StepRegistration/StepRegistration.paths'
import {
  agreementIndexPath,
  confidentialityPath,
  indexPath,
  settingsModalPath,
  settingsPath,
  standaloneAgreementPartPath,
} from 'components/paths'
import { mobileAppSupportRoutes } from 'components/routes/mobileAppSupportRoutes'
import { baseStoreFrontPath } from 'components/storefront/StoreFront.paths'
import { noticePath } from 'components/uninotice/UniNotice.paths'
import { removeLocaleFromPath } from 'functions/language'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import commonModalRoutes from 'routes/commonModalRoutes'
import unauthorizedModalRoutes from 'routes/unauthorizedModalRoutes'
import { installMobileAppBannerLayerPath } from '../InstallMobileAppBannerLayer.paths'

export const useShowBannerOnCurrentPath = (): boolean => {
  const { pathname } = useLocation()
  const pathnameWithoutLocale = mergeAllUrls(removeLocaleFromPath(pathname))
  const [show, setShow] = useState<boolean>(
    isShowBanner(pathnameWithoutLocale, pathname)
  )

  useEffect(() => {
    setShow(isShowBanner(pathnameWithoutLocale, pathname))
  }, [pathname, pathnameWithoutLocale])

  return show
}

const isShowBanner = (
  pathnameWithoutLocale: string,
  pathname: string
): boolean => {
  if (
    pathnameWithoutLocale !== indexPath &&
    pagesHideBanner.find((path) =>
      mergeAllUrls(pathnameWithoutLocale).includes(path)
    )
  ) {
    return false
  }

  if (pathname !== indexPath) {
    return true
  }

  return false
}

const pagesHideBanner: string[] = [
  ...[...commonModalRoutes, ...unauthorizedModalRoutes].reduce<string[]>(
    (acc, { path }) => {
      return [...acc, path]
    },
    []
  ),
  settingsPath,
  searchSettingsPath,
  ...stepRegistrationInnerArrayPaths,
  settingsModalPath,
  baseStoreFrontPath,
  noticePath,
  thenPartPath,
  confidentialityPath,
  agreementIndexPath,
  standaloneAgreementPartPath,
  installMobileAppBannerLayerPath,
  ...mobileAppSupportRoutes.map(({ path }) => path),
]
