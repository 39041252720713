import { mergeAllUrls } from 'functions/mergeAllUrls'
import { userProfilePath } from 'components/paths'
import {
  photoViewerBasePath,
  photoViewerPhotoIdPath,
} from 'components/page/PhotoViewer/PhotoViewer.paths'

export const photoCommentsPath = 'comments'
export const photoCommentPath = 'comment'

export const photoCommentFullPath = mergeAllUrls(
  userProfilePath,
  photoViewerBasePath,
  photoViewerPhotoIdPath,
  `${photoCommentsPath}-:contentId`
)

const fakePath = mergeAllUrls(
  photoViewerBasePath,
  photoViewerPhotoIdPath,
  `${photoCommentsPath}-:contentId`
)

export const photoCommentFakeModalPath = mergeAllUrls(fakePath)

export const photoCommentFullWithScrollToIdPath = mergeAllUrls(
  photoCommentFullPath,
  `${photoCommentPath}-:commentId`
)

export const photoCommentFakeModalWithScrollIdPath = mergeAllUrls(
  fakePath,
  `${photoCommentPath}-:commentId`
)
