import { styled } from '@linaria/react'
import { WithClassName } from 'common/types'
import React, { FC } from 'react'
import { LoaderSvg } from '../svgr/LoaderSvg'

export const Loader: FC<{} & WithClassName> = ({ className }) => {
  return (
    <Loading className={className}>
      <LoaderSvg />
    </Loading>
  )
}

export const Loading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: inherit;
  box-sizing: border-box;
`
