import { AuthorizationState } from 'reducers/authorization/AuthorizationState'

export const defaultState: AuthorizationState = {
  form: {
    login: '',
    loginMailru: '',
    domainMailru: '@mail.ru',
    password: '',
    computerLevel: false,
    name: '',
    email: '',
    emailEnable: false,
    gender: '',
    day: '',
    month: '',
    year: '',
    location: '',
    locationDisplayValue: '',
    phone: { phoneNumber: null, countryCode: null, prefix: null },
    smsCode: '',
  },
  formError: {},
  genericError: false,
  authorizing: false,
  needRegistrationLoveMailRu: false,
  loveMailruAuth: false,
  authorized: false,
  authorizedBySecret: false,
  authorizationBySecretFailed: false,
  registrated: false,
  profile: {},
  vendors: [],
  vendorsRequested: false,
  vendorsLoaded: false,
  registrationVendor: null,

  errorCode: null,
  resettingPassword: false,
  resetErrorCode: null,
  smsErrorCode: null,
  smsTimerFinished: false,
  smsCodeHasBeenSent: false,
  unexpectedServerError: false,
  hasLocation: false,
  miniLoading: false,
  loveMailruLoading: true,
  registrationDataLoaded: false,
  lastAuthMethod: null,
}
