import React, { FC } from 'react'

import {
  createAccountBalanceChannel,
  createGiftUserChannel,
  createHitListChannel,
  createHitListUpdatedSignalChannel,
  createLastUserMessageChannel,
  createMessageAddReactionChannel,
  createMessageReadedChannel,
  createMessageReadReactionChannel,
  createMessageRemoveReactionChannel,
  createMessageReplaceReactionChannel,
  createMessengerCounterChannel,
  createMessengerRequestForCommunicationCountChannel,
  createMessengerRequestForCommunicationStatusChangedChannel,
  createOrderDeliveredChannel,
  createOutgoingMessageLastUserChannel,
  createTypingMessageUserChannel,
  createUserUnbanChannel,
  createVideoChatChannel,
  createVipStatusChannel,
} from 'functions/comet'

import { useUserId } from 'hooks/useUserId'

import { WebSocketChannelsSubscriber } from 'components/system/WebSocketChannelsSubscriber'

export const Subscription: FC = () => {
  const userId = useUserId()

  if (!userId) {
    return null
  }

  return (
    <WebSocketChannelsSubscriber
      channels={[
        {
          channel: createHitListChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createMessengerCounterChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createMessengerRequestForCommunicationCountChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createMessengerRequestForCommunicationStatusChangedChannel(
            userId
          ),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createLastUserMessageChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createVideoChatChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createHitListUpdatedSignalChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createMessageReadedChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createOutgoingMessageLastUserChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createVipStatusChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createOrderDeliveredChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createGiftUserChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createAccountBalanceChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createUserUnbanChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createMessageAddReactionChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createMessageReplaceReactionChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createMessageRemoveReactionChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        {
          channel: createMessageReadReactionChannel(userId),
          min_size: 1,
          max_size: 10,
        },
        /** Нужно знать, что контакт печатает и показать это */
        {
          channel: createTypingMessageUserChannel(userId),
          min_size: 1,
          max_size: 10,
        },
      ]}
    />
  )
}
