import {
  fetchInviteFriendDataApi,
  fetchInviteFriendsHitListStatisticApi,
  InviteFriendData,
} from 'api/inviteFriendPromoApi'
import { AsyncAction } from 'actions/actions.types'

import { NodeHeaders } from 'api/NodeHeaders'

export const INVITE_FRIEND_HIT_LIST_STATISTIC =
  'INVITE_FRIEND_HIT_LIST_STATISTIC'
interface InviteFriendsHitListStatisticAction {
  type: typeof INVITE_FRIEND_HIT_LIST_STATISTIC
}

export const FETCH_INVITE_FRIEND_DATA = 'FETCH_INVITE_FRIEND_DATA'

interface FetchInviteFriendsDataAction extends AsyncAction<InviteFriendData> {
  type: typeof FETCH_INVITE_FRIEND_DATA
}

export const inviteFriendsHitListStatisticAction = (
  inviteId: number,
  inviteToken: string,
  headers: NodeHeaders,
  locale: string
) => ({
  type: INVITE_FRIEND_HIT_LIST_STATISTIC,
  promise: () =>
    fetchInviteFriendsHitListStatisticApi(
      inviteId,
      inviteToken,
      headers,
      locale
    ),
})

export const fetchInviteFriendsDataAction = (
  inviteId: number,
  inviteToken: string,
  headers: NodeHeaders,
  locale: string
) => ({
  type: FETCH_INVITE_FRIEND_DATA,
  promise: () =>
    fetchInviteFriendDataApi(inviteId, inviteToken, headers, locale),
})

export type FetchInviteFriendsPromoActionTypes =
  | InviteFriendsHitListStatisticAction
  | FetchInviteFriendsDataAction
