import { push, replace } from 'functions/router'
import {
  fetchArchiveApi,
  fetchArchiveUrlApi,
  requestGdrpDataApi,
  giveConsentGdprApi,
  rejectAgreementGdprApi,
  testPasswordAndRecallGdprApi,
} from 'api/gdprApi'
import {
  apiResultHasErrors,
  errorCodeEquals,
  errorCodeNotEquals,
} from 'api/index'
import { fetchCheckPasswordProfileAction } from 'actions/userAction'
import { indexPath } from 'components/paths'
import { apiResultWithoutErrors } from 'api/function/apiResultWithoutErrors'
import {
  resetBlockedPersonalAction,
  resetBLockedProfileAction,
  resetBlockedTrackAction,
} from 'actions/errorAction'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'
import {
  AsyncAction,
  AsyncThunkAction,
  PlainAction,
} from 'actions/actions.types'
import { paths } from 'api/generated/gdpr'
import { logoutAction } from 'actions/authorization/logoutAction'
import { DeprecatedApi6Error } from 'api/types'

export const FETCH_REJECT_AGREEMENT_GDPR = 'FETCH_REJECT_AGREEMENT_GDPR'

export const REQUEST_GDPR_DATA = 'REQUEST_GDPR_DATA'

export interface ResolveGdprDataAction extends AsyncAction {
  type: typeof REQUEST_GDPR_DATA
}

export const resolveGdprDataAction = (
  gdprRequestPath: string,
  gdrpStartedPath: string,
  gdprReadyPath: string
): AsyncThunkAction => (dispatch, getState) => {
  // TODO добавить проверку от дополнительных запросов
  dispatch({
    type: REQUEST_GDPR_DATA,
    promise: () =>
      fetchArchiveApi().then((json) => {
        if (errorCodeEquals(json, 'request_exists')) {
          dispatch(push(gdrpStartedPath))
        } else if (apiResultHasErrors(json)) {
          dispatch(push(gdprRequestPath))
        } else if (json.ready) {
          dispatch(push(gdprReadyPath))
        } else {
          dispatch(push(gdrpStartedPath))
        }
        return json
      }),
  })
}

export const FETCH_GDPR_ARCHIVE = 'FETCH_GDPR_ARCHIVE'

export interface FetchArchiveAction extends AsyncAction {
  type: typeof FETCH_GDPR_ARCHIVE
}

export const fetchArchiveAction = (errorPath: string): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const { passwordCheck } = getState().settingsReducer.form
  dispatch({
    type: FETCH_GDPR_ARCHIVE,
    promise: () =>
      fetchArchiveUrlApi(passwordCheck).then((json) => {
        if (json.downloadUrl) {
          downloadFile(json.downloadUrl)
        } else if (errorCodeNotEquals(json, 'password_is_wrong') && errorPath) {
          dispatch(push(errorPath))
        }
        return json
      }),
  })
}

export const requestArchiveAction = (
  gdrpStartedPath: string
): AsyncThunkAction => (dispatch, getState) => {
  const { passwordCheck } = getState().settingsReducer.form
  dispatch({
    type: REQUEST_GDPR_DATA,
    promise: () =>
      requestGdrpDataApi(passwordCheck).then((json) => {
        if (errorCodeEquals(json, 'request_exists')) {
          dispatch(push(gdrpStartedPath))
        } else if (apiResultWithoutErrors(json)) {
          dispatch(push(gdrpStartedPath))
        }
        return json
      }),
  })
}

export const ACCEPT_GDPR = 'ACCEPT_GDPR'

export interface AcceptGdprAction extends AsyncAction {
  type: typeof ACCEPT_GDPR
}

export const acceptGdprAction = (lexeme: string): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const { locale } = getState().systemReducer
  await dispatch({
    type: ACCEPT_GDPR,
    promise: () => giveConsentGdprApi(locale, lexeme),
  })
  dispatch(fetchMiniIfNeededAction())
  dispatch(replace(indexPath))
}

export const REMOVE_GDPR = 'REMOVE_GDPR'

export interface RemoveGdprAction extends AsyncAction {
  type: typeof REMOVE_GDPR
}

export const removeGdprAction = (
  lexeme: string,
  removePath: string
): AsyncThunkAction => async (dispatch, getState) => {
  const { locale } = getState().systemReducer

  const { result: json } = ((await dispatch({
    type: REMOVE_GDPR,
    promise: () => rejectAgreementGdprApi(locale, lexeme),
  })) as unknown) as { result: { ok: boolean } & DeprecatedApi6Error }

  if (json.ok) {
    dispatch(logoutAction())
  }

  if (errorCodeEquals(json, 'password_is_required')) {
    dispatch(push(removePath))
  }
}

export const fetchRejectAgreementGdprAction = (
  locale: string,
  lexeme: string,
  password: string
) => ({
  type: FETCH_REJECT_AGREEMENT_GDPR,
  promise: () => rejectAgreementGdprApi(locale, lexeme, password),
})

export const RECALL_GDPR = 'RECALL_GDPR'

export interface RecallGdprAction
  extends AsyncAction<
    paths['/gdpr/withdrawConsent']['post']['responses']['204']
  > {
  type: typeof RECALL_GDPR
}

export const recallGdprAction = (lexeme: string): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const { settingsReducer, systemReducer } = getState()
  const { passwordCheck } = settingsReducer.form
  const { locale, baseUrl } = systemReducer

  const { result: json } = ((await dispatch({
    type: RECALL_GDPR,
    promise: () => testPasswordAndRecallGdprApi(locale, lexeme, passwordCheck),
  })) as unknown) as { result: { ok: boolean } }

  if (json.ok) {
    dispatch(resetBlockedTrackAction())
    dispatch(resetBlockedPersonalAction())
    dispatch(resetBLockedProfileAction())

    // В незалогиненых урлах нет настоек, что приводит к 404-й
    // Редиректим на базовый урл, как будто закрываем модал
    // https://redmine.mamba.ru/issues/113693
    dispatch(replace(baseUrl))

    dispatch(logoutAction())

    return json
  }

  return json
}

export const removeGdprWithPasswordAction = (
  lexeme: string
): AsyncThunkAction => async (dispatch, getState) => {
  const { settingsReducer } = getState()
  const { passwordCheck } = settingsReducer.form

  const { result: json } = ((await dispatch(
    fetchCheckPasswordProfileAction(passwordCheck)
  )) as unknown) as {
    result: DeprecatedApi6Error & { result: unknown; ok: boolean }
  }

  if (apiResultWithoutErrors(json)) {
    if (json.ok || json.result) {
      const { locale } = getState().systemReducer
      await rejectAgreementGdprApi(locale, lexeme, passwordCheck)
      dispatch(logoutAction())

      return json
    }

    return json
  }

  return json
}

export const RESET_STATUS_GDPR = 'RESET_STATUS_GDPR'

export interface ResetGdprStatusAction extends PlainAction {
  type: typeof RESET_STATUS_GDPR
}

export const resetGdprStatusAction = () => ({
  type: RESET_STATUS_GDPR,
})

/**
 * Возможно есть какой-то более простой способ и лучше вписывающийся
 * в идеологию реакта.
 * @param downloadUrl
 */
function downloadFile(downloadUrl: string) {
  const downloadLink = document.createElement('a')
  downloadLink.href = downloadUrl
  downloadLink.download = downloadUrl
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}
