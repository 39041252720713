import { getApi6ErrorCode } from 'api/functions'
import {
  Fields,
  SingleSelectFieldsSettings,
} from 'components/page/Settings/Fields'
import { clone } from 'functions/clone'

export const errorCode = (result) => {
  return {
    errorCode: getApi6ErrorCode(result),
    settingsLoading: false,
  }
}

export const extractVipSettings = (isVipRequired, result, newState, state) => {
  if (isVipRequired) {
    return {
      ...newState,
      isVipRequired: isVipRequired,
    }
  }

  return {
    profile: { ...state.profile, ...clone(result) },
    ...newState,
    form: { ...newState.form, ...clone(result) },
    isVipRequired: isVipRequired,
  }
}

export const extractFetchFieldResult = (field, result) => {
  switch (field) {
    case 'eroticPhotos':
    case Fields.MessageSound:
    case 'atlas':
      return Boolean(result.enabled)
    case SingleSelectFieldsSettings.InviterStatusField:
      return Boolean(result.hidden)
    case Fields.ProfileVisibilityStatus:
      return result.status
    case SingleSelectFieldsSettings.Incognito:
      return result.isOn
  }
  throw new Error(`Can't extract field "${field}"`)
}
