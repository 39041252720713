import { fetchSettingServicesEnableApi } from 'api/system/settingServicesEnableApi'
import { Api6NodeHeaders } from 'api/createApiNodeHeaders'

export const FETCH_SETTING_SERVICES_ENABLE = 'FETCH_SETTING_SERVICES_ENABLE' as const

export const listServicesCheckAvailability = [
  'photoline',
  'featured_photos_x3_promo',
]

export const fetchSettingServicesEnableAction = (
  services: string[] = listServicesCheckAvailability,
  headers?: Api6NodeHeaders
) => {
  return {
    type: FETCH_SETTING_SERVICES_ENABLE,
    promise: () => fetchSettingServicesEnableApi(services, headers),
  }
}
