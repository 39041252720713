import { createLocaleQuery, fetchApi } from 'api/index'

import { NodeHeaders } from 'api/NodeHeaders'

export const fetchInviteFriendsHitListStatisticApi = (
  inviteId: number,
  inviteToken: string,
  headers: NodeHeaders,
  locale: string
) =>
  fetchApi(`/inviter/invites/${inviteId}/hit?${createLocaleQuery(locale)}`, {
    method: 'POST',
    body: JSON.stringify({
      inviteId,
      inviteToken,
    }),
    headers,
  })

export const fetchInviteFriendDataApi = (
  inviteId: number,
  inviteToken: string,
  headers: NodeHeaders,
  locale: string
) =>
  fetchApi(
    `/inviter/invite-pages/${inviteId}?inviteToken=${inviteToken}&${createLocaleQuery(
      locale
    )}`,
    {
      headers,
    }
  )

export interface ObjectPhoto {
  photoUrl: string
}

export interface InviteFriendData {
  inviterName: string
  inviteeName: string
  todayMatches: number
  users: ObjectPhoto[]
}
